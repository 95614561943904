// OK
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { DOMAIN } from 'src/constants';

const getToUrl = (to) => {
  if (!to) {
    return null;
  }
  const includeIgnoreInternalUrl = to.includes(
    'blog/thong-bao-tu-broker/dich-vu-forex-rebates-hoan-phi-forex-la-gi-.html'
  );
  const startWithUrlAnyDomain = to.startsWith('http://') || to.startsWith('https://');
  const startWithUrlVnRebates =
    to.startsWith('https://vnrebates.net') ||
    to.startsWith('https://vnrebates.org') ||
    to.startsWith('https://vnrebates.info') ||
    to.startsWith('https://wp.vnrebates.io') ||
    to.startsWith(DOMAIN);
  const startWithSlash = to.startsWith('/');
  // external url
  if (includeIgnoreInternalUrl) {
    return to;
  }
  if (startWithUrlAnyDomain && !startWithUrlVnRebates) {
    return to;
  }
  // internal url
  if (startWithUrlVnRebates) {
    return to
      .replace('https://vnrebates.net', '')
      .replace('https://vnrebates.org', '')
      .replace('https://vnrebates.info', '')
      .replace('https://wp.vnrebates.io', '')
      .replace(DOMAIN, '');
  }
  if (!startWithUrlAnyDomain && !startWithSlash) {
    return `/${to}`;
  }
  return to;
};

const VnrLink = (props) => {
  const { children, target } = props;
  const { locale } = useRouter();
  const to = getToUrl(props.to);

  if (!to || to.includes('/null')) {
    return <a {...props}>{children}</a>;
  }
  const startWithUrlDomain = to.startsWith('http://') || to.startsWith('https://');

  if (to?.includes('/kenh-tin-hieu-thuc-chien.html')) {
    return (
      <a href={`${DOMAIN}/kenh-tin-hieu-thuc-chien.html`} {...props}>
        {children}
      </a>
    );
  }

  if (startWithUrlDomain) {
    return (
      <a href={to} target="_blank" rel="noopener nofollow noreferrer" {...props}>
        {children}
      </a>
    );
  }

  if (locale) {
    return (
      <Link prefetch={false} href={to} locale={locale}>
        <a {...props}>{children}</a>
      </Link>
    );
  }

  return (
    <a href={to} target={target || ''} {...props}>
      {children}
    </a>
  );
};

export default VnrLink;
