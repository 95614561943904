import Cookies from "js-cookie";

import { setCookiesAcrossSubdomains } from "./cookieUtil";

export const localStorageUtil = {
  setItem: (...param) => {
    if (typeof window === 'undefined') return;
    try {
      return window.localStorage.setItem(...param);
    } catch (e) {
      return undefined;
    }
  },
  getItem: (...param) => {
    if (typeof window === 'undefined') return;
    try {
      return window.localStorage.getItem(...param);
    } catch (e) {
      return undefined;
    }
  },
};

export const sessionStorageUtil = {
  setItem: (...param) => {
    if (typeof window === 'undefined') return;
    try {
      return window.sessionStorage.setItem(...param);
    } catch (e) {
      return undefined;
    }
  },
  getItem: (...param) => {
    if (typeof window === 'undefined') return;
    try {
      return window.sessionStorage.getItem(...param);
    } catch (e) {
      return undefined;
    }
  },
};

export const copyDataFromLocalStorageToCookie = (code) => {
  const localStorageData = window.localStorage.getItem(code);

  if (localStorageData && localStorageData !== '') {
    setCookiesAcrossSubdomains(code, localStorageData);
    window.localStorage.removeItem(code);
  }

  return Cookies.get(code, localStorageData);
};

export const removeCookiesAcrossSubdomains = (name) => {
  const cookieDomain = '.' + window.location.hostname.split('.').slice(-2).join('.');

  Cookies.remove(name, { domain: cookieDomain })
}
