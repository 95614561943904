// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (['production', 'stage'].indexOf(process.env.NODE_ENV) !== -1) {
  Sentry.init({
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    blacklistUrls: [
      // Ads
      /pagead2\.googlesyndication\.com/i,
      /securepubads\.g\.doubleclick\.net/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    beforeSend: function (event, hint) {
      // Drop all events if query string includes `fbclid` string
      // https://stackoverflow.com/questions/64497012/typeerror-illegal-invocation-in-the-pubads-impl-xxx-js-in-mobile-safari
      if (location.search.indexOf('fbclid') !== -1) {
        return null;
      }
      // filter out UnhandledRejection errors that have no information
      if (
        event !== undefined &&
        event.exception !== undefined &&
        event.exception.values !== undefined &&
        event.exception.values.length == 1
      ) {
        var e = event.exception.values[0];
        if (
          e.type === 'UnhandledRejection' &&
          e.value === 'Non-Error promise rejection captured with value: '
        ) {
          return null;
        }
        if (
          e.type === 'UnhandledRejection' &&
          e.value === 'Non-Error promise rejection captured with value: undefined'
        ) {
          return null;
        }
        if (e.type === 'ReferenceError' && e.value === `Can't find variable: zaloJSV2`) {
          return null;
        }
        if (e.type === 'ReferenceError' && e.value === `zaloJSV2 is not defined`) {
          return null;
        }
        if (
          e.value ===
          `SecurityError: Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document.`
        ) {
          return null;
        }
      }

      if (event !== undefined && event.breadcrumbs && event.breadcrumbs.length) {
        const isErrorApiBanner = event.breadcrumbs.some((item) => {
          return (
            item.message === 'Error: Network Error' &&
            item.data &&
            item.data.arguments &&
            item.data.arguments[0] &&
            item.data.arguments[0].config &&
            item.data.arguments[0].config.url &&
            item.data.arguments[0].config.url.startsWith(
              'https://api.vnrebates.io/api/v1/banner-ads'
            )
          );
        });
        if (isErrorApiBanner) {
          return null;
        }
      }
      return event;
    },
  });
}
