import dayjs from 'dayjs';
import produce from 'immer';

import { createNote } from '../../services/crmNote.service';
import { getRebateByListUserId, rebateForUser } from '../../services/rebate.service';

export const rebate = {
  name: 'rebate',
  state: {
    ids: [],
    // loaded month detail by author
    months: {}, // month -> author -> list data ids
  },
  reducers: {
    setData: produce((state, payload = {}) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    }),
  },
  effects: (dispatch) => ({
    async loadRebate(payload) {
      const { data } = await getRebateByListUserId(payload);
      dispatch.entityRebate.saves(data.content);
    },
    async doRebate(payload) {
      const res = await rebateForUser(payload);
      if (res.data) {
        dispatch.entityRebate.saves(res.data.content);
        dispatch.entityWalletRebate.saves(res.data.metaEntity.walletRebates);
      }

      return res;
    },
    async pendingRebate({ month, year, ...data }) {
      const resNote = await createNote({
        appointmentDate: dayjs()
          .year(year)
          .month(month - 1)
          .startOf('month')
          .startOf('day'),
        content: `Không thanh toán Rebate ${month}/${year}`,
        ...data,
      });

      return resNote;
    },
  }),
};
