import React, { useState, useEffect } from "react";
import { Upload, message, Avatar } from 'antd';
import { uploadAvatar } from '@services/storageFile.service';
import CameraIcon from "@components/Icons/CameraIcon";
import styles from "./Profile.module.scss";

const UploadAvatar = ({id, currentAvatar, setImageUrl }) => {  
  const [fileUpload, setFileUpload] = useState([]);
  
  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    onRemove: () => {
      setFileUpload([]);
    },
    beforeUpload: file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Vui lòng chọn ảnh định dạng JPG/PNG!');
      };
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return message.error('Vui lòng chọn ảnh nhỏ hơn 2MB!');
      };    
      setFileUpload([file]);
      return isJpgOrPng && isLt2M;
    },
    fileUpload,
  };

  const handleUploadAvatar= async () => {
    const formData = new FormData();
    formData.append('file', fileUpload[0]);

    const res = await uploadAvatar(id, formData);
    
    if (res?.data?.content?.originalUrl) {
      setImageUrl(res?.data?.content?.originalUrl);
      message.success('Upload ảnh thành công.');
    }
  };

  useEffect(() => {
    if (fileUpload.length === 0) {
      return;
    }
    handleUploadAvatar();
  }, [fileUpload]);
  
  return (
    <div className={styles.avatarWrapper}>
      <Avatar size={120} src={currentAvatar} />
      <div className={styles.uploadAvatarButton}>
        <Upload {...props}>
          <CameraIcon />
        </Upload>
      </div>
      
    </div>
  );
};
export default UploadAvatar;