import { request, requestDownload } from '../utils/request';

export const getTotalExpenditureByUser = ({ userId } = {}) =>
  request({
    url: `/rebates/getTotalExpenditureByUser`,
    method: 'GET',
    params: { userId },
    data: {},
  });

export const createRebates = (rebates) =>
  request({
    url: `/rebates`,
    method: 'POST',
    params: {},
    data: rebates,
  });

export const rebateForUser = ({ userId, ...data }) =>
  request({
    url: `/rebates/user/${userId}`,
    method: 'POST',
    data,
  });

export const getRebateByListUserId = ({ userIds } = {}) =>
  request({
    url: `/rebates/getByListUserId`,
    method: 'GET',
    params: { userIds },
    data: {},
  });

export const getRebatesByUserIdAndRebateTime = ({ rebateTime, userId } = {}) =>
  request({
    url: '/rebates/getByUserIdAndRebateTime',
    method: 'GET',
    params: { rebateTime, userId },
    data: {},
  });

export const getTotalWaitingPay = () =>
  request({
    url: '/rebates/getTotalWaitingPay',
    method: 'GET',
    params: {},
    data: {},
  });

export const getTotalBrokerAvenue = (params) =>
  request({
    url: '/rebates/getTotalBrokerAvenue',
    method: 'GET',
    params,
  });

export const exportUserRebateInMonth = (params) =>
  requestDownload({
    url: '/rebates/exportUserRebateInMonth',
    method: 'GET',
    params,
  });

export const exportMonthlyUserRebate = (params) =>
  requestDownload({
    url: '/rebates/exportMonthlyUserRebate',
    method: 'GET',
    params,
    defaultFileName: `user-rebate-report-${params.month}.xlsx`,
  });

export const exportMonthBrokerRebate = (params) =>
  requestDownload({
    url: '/rebates/exportBrokerRebateInMonth',
    method: 'GET',
    params,
  });

export const getRebateByMonth = (params) =>
  request({
    url: '/rebates/getByMonth',
    method: 'GET',
    params,
  });

export const getUserWithMinTotalRebate = (minimumExpenditure) =>
  request({
    url: '/rebates/getUserWithMinTotalRebate',
    method: 'GET',
    params: { minimumExpenditure: minimumExpenditure },
  });

export const getUserHaveFirstRebateInRangeTime = ({rebateTimeFrom, rebateTimeTo}) =>
  request({
    url: '/rebates/getUserHaveFirstRebateInRangeTime',
    method: 'GET',
    params: { rebateTimeFrom, rebateTimeTo },
  });

export const searchRebates = ({email, brokerAccountNumber, brokerId, isPaid, rebateTimeFrom, rebateTimeTo, page = 0, size = 20} = {}) =>
  request({
    url: '/rebates/searchRebates',
    method: 'GET',
    params: { email, brokerAccountNumber, brokerId, isPaid, rebateTimeFrom, rebateTimeTo, page, size },
  });
  
export const sendMailsPreviousMonthStatistic = ({userIds, preview}) =>
  request({
    url: '/rebates/sendMailsPreviousMonthStatistic',
    method: 'GET',
    params: { userIds: userIds, preview: preview },
  });

export const sendMailSignalGroup = ({userIds, preview}) =>
  request({
    url: '/rebates/sendMailSignalGroup',
    method: 'GET',
    params: { userIds: userIds, preview: preview },
  });

export const uploadRebatesFromFile = ({rebates, brokerId}) =>
  request({
    url: `/rebates/handleDataRebatesFromFile`,
    method: 'POST',
    params: {brokerId: brokerId},
    data: rebates,
  });