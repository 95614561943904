import { useAuth } from '@components/Auth';
import { checkValidAgencyCode } from '@services/user.service';
import { parseJwt } from '@utils/auth-helper';
import { getCookie, setCookie } from '@utils/cookieUtil';
import { getQueryParams } from '@utils/urlUtil';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GOOGLE_CLIENT_ID, LOGIN_STATUS } from 'src/constants';
import { useScript } from 'src/hook/useScript';

import { getTrackingDataAndLogin } from './index';

const GooleOneTap = () => {
  const status = useScript('https://accounts.google.com/gsi/client');
  const { signed } = useAuth();
  const dispatchApp = useDispatch().app;
  const positionLogin = useSelector((state) => state.app.auth.positionLogin);
  const afterLoginSuccess = useSelector((state) => state.app.auth.afterLoginSuccess);
  const router = useRouter();
  const { ref } = getQueryParams();
  const [agencyCode, setAgencyCode] = useState();

  const checkAgencyCode = async (code) => {;
    const res = await checkValidAgencyCode(code);
    if (res?.data) {
      setAgencyCode(code);
      setCookie('agency-code', code, 15);
    } else {
      const savedCodeFromCookie = getCookie('agency-code');
      if (savedCodeFromCookie && savedCodeFromCookie !== '') {
        setAgencyCode(savedCodeFromCookie);
      };
    }
    return res?.data;
  };
  useEffect(() => {
    if (!ref || ref === '') return;
    checkAgencyCode(ref);
  }, [ref]);

  const handleCredentialResponse = async (response) => {
    const profile = parseJwt(response.credential);
    const userProfile = {
      id: profile.sub,
      name: profile.name,
      firstName: profile.given_name,
      lastName: profile.family_name,
      email: profile.email,
      profilePicURL: profile.picture,
    };

    await getTrackingDataAndLogin({
      data: {
        profile: userProfile,
        provider: 'google',
        token: {
          idToken: response.credential,
        },
      },
      router,
      dispatchApp,
      redirectUrl: '/dashboard',
      positionLogin,
      afterLoginSuccess,
      agencyCode
    });
  };

  useEffect(() => {
    const google = window.google;
    if (!google) return;
    if (status !== 'ready') return;
    if (signed !== LOGIN_STATUS.NOT_LOGGED_IN) {
      google.accounts.id.cancel();
      return;
    }

    setTimeout(() => {
      google.accounts?.id?.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        auto_select: true,
        context: 'signin',
        prompt_parent_id: 'google-one-tap',
        cancel_on_tap_outside: false,
      });
      google.accounts?.id?.prompt();
    }, 1000);
  }, [signed, status]);

  return (
    <div id="google-one-tap" style={{ position: 'fixed', right: 20, top: 70, zIndex: 1001 }}></div>
  );
};

export default GooleOneTap;
