import produce from 'immer';

import {
  createMonthBonus,
  getMonthBonus,
  updateMonthBonus,
} from '../../services/monthBonus.service';

export const manageMonthBonus = {
  name: 'manageMonthBonus',
  state: {
    ids: [],
    pagination: {
      totalElements: 0,
      number: -1,
      size: 10,
    },
  },
  reducers: {
    setData: produce((state, payload = {}) => {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });
    }),
  },
  effects: dispatch => ({
    async loadMonthBonusData({ page = 0, size = 10 }) {
      const res = await getMonthBonus({ page, size });

      const ids = res.data.content.map(record => record.id);
      dispatch.entityMonthBonus.saves(res.data.content);
      dispatch.manageMonthBonus.setData({
        ids,
        pagination: (({ totalElements, number, size }) => ({ totalElements, number, size }))(
          res.data
        ),
      });
    },
    async create(data, state) {
      const res = await createMonthBonus(data);

      dispatch.entityMonthBonus.saves([res.data]);
      dispatch.manageMonthBonus.setData({
        ids: [res.data.id, ...state.manageMonthBonus.ids],
      });
    },
    async update(data) {
      const res = await updateMonthBonus(data);

      dispatch.entityMonthBonus.replaces([res.data]);
    },
  }),
};
