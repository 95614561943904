import { request } from "../utils/request";

export const getRebateMonthlyByListUserId = ({ userIds } = {}) =>
  request({
    url: "/rebate-monthly/getByListUserId",
    method: "GET",
    params: { userIds },
  });

export const updateRebateMonthlyDescription = (data) =>
  request({
    url: "/rebate-monthly/description",
    method: "POST",
    data,
  });

export const getRebateMonthlyByTime = (params) =>
  request({
    url: "/rebate-monthly/getByMonth",
    method: "GET",
    params,
  });

export const getUserRebateMonthlyAmountByTime = ({ userId, rebateMonthTime }) =>
  request({
    url: `/rebate-monthly/users/${userId}`,
    method: "GET",
    params: { rebateMonthTime },
  });

export const getTotalUserRebate = userId =>
  request({
    url: `/rebate-monthly/users/${userId}/total-rebate`,
    method: "GET",
  });

export const updateRebateMonthlyStatus = ({ id, data }) =>
  request({
    url: `/rebate-monthly/${id}/status`,
    method: "POST",
    data,
  });

export const randomRebatePreMonth = () =>
  request({
    url: `/rebate-monthly/randomRebatePreMonth`,
    method: "GET",
  });

export const getByUserIdIn12Months = ({ userId }) =>
  request({
    url: `/rebate-monthly/getByUserIdIn12Months`,
    method: "GET",
    params: { userId },
  });
