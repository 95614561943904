import { request } from '../utils/request';

export const getAnonymousUserData = ({ userName, newUserEmail, phone, province, services, market, startDate, endDate, page, size }) =>
  request({
    url: '/anonymous-user-data',
    method: 'GET',
    params: {
      userName, newUserEmail, phone, province, services, market, startDate, endDate, 
      page,
      size
    },
  })

export const createAnonymousUserData = anonymousUserData =>
  request({
    url: '/anonymous-user-data',
    method: 'POST',
    data: anonymousUserData,
  });
