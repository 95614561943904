import { request } from '../utils/request';

export const getRebateBrokerMonthlyByMonth = params =>
  request({
    url: '/rebate-broker-monthly',
    method: 'GET',
    params,
  });

export const saveRebateBrokerMonthly = data =>
  request({
    url: '/rebate-broker-monthly',
    method: 'POST',
    data,
  });
