import { request } from '../utils/request';

export const getCommissionSiteMonthlyByMonth = (params) => {
  return request({
    url: '/commission-site-monthly',
    method: 'GET',
    params,
  });
};

export const saveCommissionSiteMonthly = (data) =>
  request({
    url: '/commission-site-monthly',
    method: 'POST',
    data,
  });
