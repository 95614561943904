import { request } from '../utils/request';

export const getRebateSiteMonthlyByMonth = (params) => {
  console.log('params', params);
  return request({
    url: '/rebate-site-monthly',
    method: 'GET',
    params,
  });
};

export const saveRebateSiteMonthly = (data) =>
  request({
    url: '/rebate-site-monthly',
    method: 'POST',
    data,
  });
