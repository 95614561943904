import GoogleSocial from '@components/Icons/googleSocial';
import React, { useEffect, useRef, useState } from 'react';
import { GOOGLE_CLIENT_ID } from 'src/constants';
import { useScript } from 'src/hook/useScript';

import styles from './SocialLogin.module.scss';

const GoogleLoginButton = ({ onLoginSuccess, onLoginFailure = () => {} }) => {
  useScript('https://apis.google.com/js/api.js');
  const [id] = useState(`sl${Math.floor(Math.random() * 0xffff)}`);
  const loginRef = useRef();

  const handleSuccess = (googleUser) => {
    const profile = googleUser.getBasicProfile();
    const authResponse = googleUser.getAuthResponse(true);

    const userProfile = {
      id: profile.getId(),
      name: profile.getName(),
      firstName: profile.getGivenName(),
      lastName: profile.getFamilyName(),
      email: profile.getEmail(),
      profilePicURL: profile.getImageUrl(),
    };
    const token = {
      accessToken: authResponse.access_token,
      idToken: authResponse.id_token,
      scope: authResponse.scope,
      expiresIn: authResponse.expires_in,
      firstIssued_at: authResponse.first_issued_at,
      expiresAt: authResponse.expires_at,
    };
    onLoginSuccess({
      provider: 'google',
      profile: userProfile,
      token,
    });
    if (window.gtag_report_conversion_register)
      window.gtag_report_conversion_register();
  };

  const attachSignin = (element) => {
    if (element && window.gapi && window.gapi.auth2)
      window.gapi.auth2
        .getAuthInstance()
        .attachClickHandler(element, {}, handleSuccess, onLoginFailure);
  };
  const { gapi } = window;
  useEffect(() => {
    if (!gapi) {
      return;
    }
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: GOOGLE_CLIENT_ID,
        scope: 'profile email',
      });
      attachSignin(loginRef.current);
    });
  }, [gapi]);

  return (
    <div id={id} className={styles.iconSocial} ref={loginRef}>
      <GoogleSocial className={styles.iconGoogleSocial} />
    </div>
  );
};

export default React.memo(GoogleLoginButton);
