import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: 0,
  authorId: null,
  authorName: null,
  pageView: 0,
  postId: null,
  postUrl: null,
  publicDate: null,
  postTitle: null,
};

export const entityPostPageView = {
  name: 'entityPostPageView',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
