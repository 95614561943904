import { request } from '../utils/request';

export const createNote = ({ appointmentDate, content, customerId } = {}) =>
  request({
    url: '/crm/notes',
    method: 'POST',
    params: {},
    data: { appointmentDate, content, customerId },
  });

export const countCustomerInRangeAppointmentDate = ({
  appointmentDateFrom,
  appointmentDateTo,
} = {}) =>
  request({
    url: '/crm/notes/countCustomerInRangeAppointmentDate',
    method: 'GET',
    params: { isFindFollowCustomer: true, appointmentDateFrom, appointmentDateTo },
    data: {},
  });

export const findCrmNotePagingByCustomerId = ({ customerId, page, size } = {}) =>
  request({
    url: '/crm/notes/findPagingByCustomerId',
    method: 'GET',
    params: { customerId, page, size },
    data: {},
  });

export const findAllFollowCustomer = () =>
  request({
    url: '/crm/notes/findAllFollowCustomer',
    method: 'GET'
  });
  