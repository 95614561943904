import { request } from '@utils/request';

export const resizeThumbnailByWidth = (url, width) =>
  request({
    url: '/thumbnail-image/createFromUrl',
    method: 'POST',
    params: {
      imageUrl: url,
      width: width,
    },
  });

export const createThumbnailByWidth = data => {
  if (process.env.NODE_ENV === "development") {
    return {data: {content: []}} 
  }
  return request({
    url: '/thumbnail-image/getThumbnailByListSourceUrlAndWidth',
    method: 'POST',
    data,
  });
};

export const searchThumbnail = ({sourceUrl, originalUrl, width, fromSize, toSize, page, size}) =>
  request({
    url: '/thumbnail-image/search',
    method: 'GET',
    params: {sourceUrl, originalUrl, width, fromSize, toSize, page, size},
  });
