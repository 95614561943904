import styles from "./ArrowFlip.module.scss";

const ArrowFlip = ({open}) => {
  return (
    <div className={open ? styles.flipCardOpen : styles.flipCard}>
      <div className={styles.flipCardInner}>
        <div className={styles.flipCardFront}>
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
            <path d="M0.449451 0.0147126C0.568701 -0.00677948 0.691566 0.0150129 0.812738 0.0150129H10.1734C10.2979 0.0150129 10.4242 -0.00789803 10.5468 0.0143641C10.582 0.0207611 10.6166 0.0308698 10.65 0.0446296C10.7416 0.0823363 10.8202 0.145836 10.8764 0.227406C10.9325 0.308976 10.9638 0.405103 10.9663 0.504096C10.9688 0.60309 10.9425 0.700685 10.8906 0.785013C10.6806 1.09501 6.4006 7.21501 6.0306 7.73501C5.96777 7.81563 5.88737 7.88084 5.79552 7.9257C5.70368 7.97056 5.60282 7.99387 5.5006 7.99387C5.39839 7.99387 5.29753 7.97056 5.20569 7.9257C5.11384 7.88084 5.03344 7.81563 4.9706 7.73501C4.7106 7.36501 0.400605 1.21501 0.100605 0.765013C0.0524543 0.681316 0.0292571 0.58559 0.0337517 0.489136C0.0382464 0.392682 0.0702452 0.299527 0.12597 0.220671C0.181695 0.141815 0.25882 0.0805496 0.348238 0.0441082C0.381015 0.03075 0.414943 0.0209319 0.449451 0.0147126Z" fill="#B8B8B8"/>
          </svg>
        </div>
        <div className={styles.flipCardBack}>
          <svg className={styles.flipCardBackSvg} xmlns="http://www.w3.org/2000/svg" width="11" height="8" viewBox="0 0 11 8" fill="none">
              <path d="M0.449451 0.0147126C0.568701 -0.00677948 0.691566 0.0150129 0.812738 0.0150129H10.1734C10.2979 0.0150129 10.4242 -0.00789803 10.5468 0.0143641C10.582 0.0207611 10.6166 0.0308698 10.65 0.0446296C10.7416 0.0823363 10.8202 0.145836 10.8764 0.227406C10.9325 0.308976 10.9638 0.405103 10.9663 0.504096C10.9688 0.60309 10.9425 0.700685 10.8906 0.785013C10.6806 1.09501 6.4006 7.21501 6.0306 7.73501C5.96777 7.81563 5.88737 7.88084 5.79552 7.9257C5.70368 7.97056 5.60282 7.99387 5.5006 7.99387C5.39839 7.99387 5.29753 7.97056 5.20569 7.9257C5.11384 7.88084 5.03344 7.81563 4.9706 7.73501C4.7106 7.36501 0.400605 1.21501 0.100605 0.765013C0.0524543 0.681316 0.0292571 0.58559 0.0337517 0.489136C0.0382464 0.392682 0.0702452 0.299527 0.12597 0.220671C0.181695 0.141815 0.25882 0.0805496 0.348238 0.0441082C0.381015 0.03075 0.414943 0.0209319 0.449451 0.0147126Z" fill="#B8B8B8"/>
            </svg>
        </div>
      </div>
    </div>
  )
};
export default ArrowFlip;