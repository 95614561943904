import baseEntity from './baseEntity.model';
const ENTITY_DEFAULT = {
  id: undefined,
  code: undefined,
  name: undefined,
  image: undefined,
  paymentType: undefined,
  templateReasonInvalidAccount: undefined,
  rateRebateUserCurrencyText: undefined,
  rateRebateUserCurrency: undefined,
  rateRebateUserCurrencyType: undefined,
  rateRebateUserGoldText: undefined,
  rateRebateUserGold: undefined,
  rateRebateUserGoldType: undefined,
  tutorialTransferIBType: undefined,
  tutorialTransferIBReason: undefined,
  tutorialTransferIBBtnOpenAccountText: undefined,
  tutorialTransferIBBtnOpenAccountUrl: undefined,
  tutorialTransferIBOpenAccountContent: undefined,
  tutorialTransferIBEmailSendTo: undefined,
  tutorialTransferIBEmailSubject: undefined,
  tutorialTransferIBEmailContent: undefined,
  tutorialTransferIBHiddenStepSubmitAccount: undefined,
};

export const entityBrokerInWP = {
  name: 'entityBrokerInWP',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
