import FacebookSocial from '@components/Icons/facebookSocial';
import { Button } from 'antd';
import React, { useLayoutEffect } from 'react';

import styles from './SocialLogin.module.scss';

const FacebookLoginButton = ({ onLoginSuccess }) => {
  /**
   * Parse data
   */
  const handleSuccess = (response) => {
    const userProfile = {
      id: response.id,
      name: response.name,
      firstName: response.first_name,
      lastName: response.last_name,
      email: response.email,
      profilePicURL: response.picture.data.url,
    };
    const token = {
      accessToken: response.accessToken,
      expiresAt: new Date().getSeconds() + response.expiresIn,
    };
    onLoginSuccess({
      provider: 'facebook',
      profile: userProfile,
      token,
    });
    if (window.gtag_report_conversion_register) window.gtag_report_conversion_register();
  };

  /**
   * Handle login response
   */
  const facebookLoginHandler = (response) => {
    if (response && response.status === 'connected') {
      window.FB.api('/me?fields=id,name,email,first_name,last_name,picture', (me) => {
        handleSuccess({
          ...me,
          accessToken: response.authResponse.accessToken,
          expiresIn: response.authResponse.expiresIn,
        });
      });
    }
  };

  /**
   * Check login status
   */
  const checkLoginStatus = () => {
    if (window.FB) window.FB.getLoginStatus(facebookLoginHandler, true);
  };

  /**
   * Check login status and call login api is user is not logged in
   */
  const facebookLogin = async () => {
    const { FB } = window;
    if (!FB) return;

    FB.getLoginStatus((response) => {
      if (response && response.status === 'connected') {
        facebookLoginHandler(response);
      } else {
        FB.login(facebookLoginHandler, { scope: 'public_profile, email' });
      }
    }, true);
  };

  useLayoutEffect(() => {
    checkLoginStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button type="text" className={styles.iconSocial} onClick={facebookLogin} role="button">
      <FacebookSocial />
    </Button>
  );
};

export default React.memo(FacebookLoginButton);
