import React from 'react';

const FacebookSocial = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#1976D2"
        d="M24.5 0h-21C1.57 0 0 1.57 0 3.5v21C0 26.43 1.57 28 3.5 28h21c1.93 0 3.5-1.57 3.5-3.5v-21C28 1.57 26.43 0 24.5 0z"
      />
      <path
        fill="#FAFAFA"
        d="M23.625 14H19.25v-3.5c0-.966.784-.875 1.75-.875h1.75V5.25h-3.5C16.35 5.25 14 7.6 14 10.5V14h-3.5v4.375H14V28h5.25v-9.625h2.625L23.625 14z"
      />
    </svg>
  );
};

export default FacebookSocial;
