import modalHook from "../components/ModalHook/model";
import * as app from "./app";
import * as entities from "./entities";
import * as pages from "./pages";
import * as pageState from "./pageState";

const reducer = () => ({
  name: 'vnrebates.net',
  redux: {},
  models: {
    ...app,
    ...pageState,
    ...entities,
    ...pages,
    modalHook,
  },
});

export default reducer;
