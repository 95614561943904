const getDataLayer = () => {
  if (typeof window === 'undefined') return [];
  if (process.env.NODE_ENV === 'development') {
    window.dataLayerDev = window.dataLayerDev || [];
    return window.dataLayerDev;
  }
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
};

export const triggerEventRegistrationComplete = (user, positionLogin = '') => {
  if (!user.registerNew) return;
  const dataLayer = getDataLayer();
  console.info('GTM: registrationComplete');
  // tracking position event
  let positionEventValue = window.location.href;
  dataLayer.push({ PopupLogin: positionEventValue });
  // push event
  dataLayer.push({
    event: 'registrationComplete',
  });
};

export const triggerEventUserFillInformation = (user) => {
  if (!user?.phone) return;
  const dataLayer = getDataLayer();
  
  // tracking position event
  let positionEventValue = window.location.href;
  dataLayer.push({ 
    PopupLogin: positionEventValue 
  });
  // push event
  dataLayer.push({
    event: 'userFillInformation',
  });
};

export const triggerEventVerifyUserBrokerAccount = ({userStartPage, trafficSource}) => {
  const dataLayer = getDataLayer();
  
  dataLayer.push({
    event: 'verifyUserBrokerAccount',
    userStartPage: userStartPage,
    trafficSource: trafficSource
  });
};

export const triggerEventCreateBrokerAccountComplete = () => {
  const dataLayer = getDataLayer();
  console.info('GTM: createBrokerAccount');
  // tracking position event
  let positionEventValue = window.location.href;
  dataLayer.push({ PopupRebate: positionEventValue });
  // push event
  dataLayer.push({ event: 'Rebate' });
};

export const triggerEventRebatesUser = ({userStartPage, trafficSource}) => {
  const dataLayer = getDataLayer();
  
  dataLayer.push({
    event: 'userRebate',
    userStartPage: userStartPage,
    trafficSource: trafficSource
  });
};

export const triggerEventImpressionRebatePopup = ({ positionEvent = '' }) => {
  const dataLayer = getDataLayer();
  // tracking position event
  let positionEventValue = positionEvent;
  if (!positionEventValue || positionEventValue === '') {
    positionEventValue = window.location.href;
  }
  // push event
  dataLayer.push({ PopupRebate: positionEventValue });
  dataLayer.push({ event: 'impressionRebate' });
};

export const triggerEventImpressionLoginPopup = ({ positionEvent = '' }) => {
  const dataLayer = getDataLayer();
  // tracking position event
  let positionEventValue = positionEvent;
  if (!positionEventValue || positionEventValue === '') {
    positionEventValue = window.location.href;
  }
  // push event
  dataLayer.push({ PopupLogin: positionEventValue });
  dataLayer.push({ event: 'impressionLogin' });
};

export const triggerEventComment = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'comment',
  });
};

export const triggerEventRating = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'rating',
  });
};

export const triggerEventShowPopupEbookPurchase = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'show__popup_ebook_purchase',
  });
};

export const triggerEventEbookPurchaseSuccess = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'ebook_purchase_success',
  });
};

export const triggerEventEbookPurchaseError = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'ebook_purchase_error',
  });
};

export const triggerEventShowNotiO4 = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'show_noti_o4',
  });
};

export const triggerEventClickNotiO4 = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'click_noti_o4',
  });
};


export const triggerEventShowNotiO5 = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'show_noti_o5',
  });
};

export const triggerEventClickNotiO5 = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'click_noti_o5',
  });
};


export const triggerEventShowNotiO4AndO5 = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'show_noti_o4_and_o5',
  });
};

export const triggerEventClickNotiO4AndO5 = () => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: 'click_noti_o4_and_o5',
  });
};

export const triggerEventShowNotifyBottomLeft = (eventShowName) => {
  const dataLayer = getDataLayer();
  dataLayer.push ({ 
    event: eventShowName,
  });
}

export const triggerEventClickNotifyBottomLeft = (eventClickName) => {
  const dataLayer = getDataLayer();
  dataLayer.push({
    event: eventClickName,
  });
}