import React from 'react';

const LoginWhite = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M14 24.5c5.799 0 10.5-4.701 10.5-10.5S19.799 3.5 14 3.5 3.5 8.201 3.5 14 8.201 24.5 14 24.5z"
      />
      <path
        stroke="#ffffff"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M14 17.5c2.416 0 4.375-1.959 4.375-4.375S16.416 8.75 14 8.75s-4.375 1.959-4.375 4.375S11.584 17.5 14 17.5z"
      />
      <path
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.978 21.806c.66-1.295 1.664-2.384 2.904-3.144C11.12 17.902 12.546 17.5 14 17.5c1.454 0 2.88.402 4.119 1.162 1.24.76 2.244 1.849 2.903 3.145"
      />
    </svg>
  );
};

export default LoginWhite;
