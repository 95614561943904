import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  currentBalance: undefined,
  totalReceived: undefined,
  ownerId: undefined,
};

export const entityWalletRebate = {
  name: 'entityWalletRebate',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
