import { Modal } from 'antd';
import React from 'react';

import useModal from './useModal';

const ModalHook = ({ id, title = '', ...props }) => {
  const [modalData = {}, , hiddenModal] = useModal(id);
  const onOk = async () => {
    if (props.onOk) {
      const result = await props.onOk();
      if (result !== false) {
        hiddenModal();
      }
    } else {
      hiddenModal();
    }
  };

  return (
    <Modal
      wrapClassName='vnrb-comp-popup-redux'
      title={title}
      footer={null}
      cancelText="Hủy"
      visible={modalData.visible}
      onCancel={() => hiddenModal()}
      style={{ top: 20 }}
      {...props}
      onOk={onOk}
    >
      {props.children}
    </Modal>
  );
};

export { useModal };

export default ModalHook;
