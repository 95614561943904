import React from 'react';

const GoogleSocial = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#FBBB00"
          d="M6.205 16.92l-.974 3.64-3.563.075C.604 18.66 0 16.4 0 14c0-2.322.565-4.51 1.565-6.438h.001l3.172.581 1.389 3.152c-.29.848-.45 1.758-.45 2.705 0 1.028.187 2.012.528 2.92z"
        />
        <path
          fill="#518EF8"
          d="M27.755 11.385c.161.847.245 1.721.245 2.615 0 1.002-.105 1.98-.306 2.923-.682 3.21-2.462 6.012-4.93 7.995v-.001l-3.995-.204-.565-3.53c1.637-.96 2.916-2.461 3.59-4.26h-7.486v-5.538h13.447z"
        />
        <path
          fill="#28B446"
          d="M22.764 24.917C20.367 26.848 17.319 28 14 28 8.67 28 4.033 25.02 1.67 20.635l4.537-3.714c1.182 3.155 4.226 5.401 7.794 5.401 1.534 0 2.971-.414 4.204-1.138l4.56 3.733z"
        />
        <path
          fill="#F14336"
          d="M22.936 3.223l-4.535 3.713C17.125 6.138 15.616 5.678 14 5.678c-3.65 0-6.75 2.349-7.873 5.617l-4.56-3.733h-.002C3.895 3.069 8.59 0 14 0c3.397 0 6.512 1.21 8.936 3.223z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H28V28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleSocial;
