import { useEffect } from "react";
import { useDispatch } from "react-redux";

const Startup = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // call init auth
    dispatch.app.initAuth();
  }, []);

  return children;
};

export default Startup;
