import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  month: undefined,
  year: undefined,
  bonus: undefined,
  createdDate: undefined,
  lastModifiedDate: undefined,
};

export const entityMonthBonus = {
  name: 'entityMonthBonus',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
