import produce from 'immer';

const defaultState = {
  reloadTable: false,
};
//  model
export const pageManageUserFreeBook = {
  name: 'pageManageUserFreeBook',
  state: {
    ...defaultState,
  },

  reducers: {
    initState: produce(state => {
      state = defaultState;
      return state;
    }),
    saveReloadTable: produce((state, isReloadTable) => {
      state.reloadTable = isReloadTable;
      return state;
    }),
  },
};
