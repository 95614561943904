import {
  ACCESS_ROLE,
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRED,
  ACCESS_TOKEN_TYPE,
  ACCESS_USER,
  AUTH_HEADER,
} from '../constants';
import { setCookiesAcrossSubdomains } from './cookieUtil'
import { copyDataFromLocalStorageToCookie, removeCookiesAcrossSubdomains } from './storageUtil';

export const isServer = typeof window === 'undefined';

export const setInfoUserLogin = ({ data }) => {
  console.log('setInfoUserLogin', data);
  if (isServer) return;
  try {
    if (data.token) {
      setCookiesAcrossSubdomains(ACCESS_TOKEN, data.token);
      setCookiesAcrossSubdomains(ACCESS_TOKEN_TYPE, data.tokenType || 'Bearer');
      setCookiesAcrossSubdomains(ACCESS_ROLE, data.roles || []);
      setCookiesAcrossSubdomains(ACCESS_TOKEN_EXPIRED, data.expired);
      setCookiesAcrossSubdomains(
        ACCESS_USER,
        JSON.stringify({
          ...(data.user || {}),
        })
      );
    }
  } catch (e) {}
};

const getInfoUserLogin = () => {
  if (isServer) return {};
  let expired;
  let token = '';
  let user = {};
  try {
    expired = copyDataFromLocalStorageToCookie(ACCESS_TOKEN_EXPIRED);
    token = copyDataFromLocalStorageToCookie(ACCESS_TOKEN) || '';
    user = copyDataFromLocalStorageToCookie(ACCESS_USER) || {};
    
    user = JSON.parse(user);
  } catch (e) {
    user = {};
    return { token, user, expired };
  }
  return { token, user, expired };
};

export const updateInfoUserLogin = (dataUpdate) => {
  if (isServer) return {};
  const { user } = getInfoUserLogin();
  try {
    setCookiesAcrossSubdomains(
      ACCESS_USER,
      JSON.stringify({
        ...(user || {}),
        ...dataUpdate,
      })
    );
  } catch (e) {}
};

const getHeaderAuthorization = (token) => {
  if (isServer) {
    return {};
  }
  try {
    const t = token || copyDataFromLocalStorageToCookie(ACCESS_TOKEN);
    const type = copyDataFromLocalStorageToCookie(ACCESS_TOKEN_TYPE) || 'Bearer';

    return !!t && !!type ? { [AUTH_HEADER]: `${type} ${t}` } : {};
  } catch (e) {
    return {};
  }
};

const clearUserInfo = () => {
  if (isServer) return;
  try {
    window.localStorage.removeItem(ACCESS_ROLE);
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(ACCESS_TOKEN_TYPE);
    window.localStorage.removeItem(ACCESS_USER);
    window.localStorage.removeItem(ACCESS_TOKEN_EXPIRED);
    removeCookiesAcrossSubdomains(ACCESS_ROLE);
    removeCookiesAcrossSubdomains(ACCESS_TOKEN);
    removeCookiesAcrossSubdomains(ACCESS_TOKEN_TYPE);
    removeCookiesAcrossSubdomains(ACCESS_USER);
    removeCookiesAcrossSubdomains(ACCESS_TOKEN_EXPIRED);
  } catch (e) {}
};

export const getMetaStartPage = () => {
  if (isServer) return {};
  let userstartpage = '';
  let sessionstartpage = '';

  try {
    if (localStorage.getItem('startpage') == null) {
      localStorage.setItem('startpage', window.location.href);
      userstartpage = window.location.href;
    } else {
      userstartpage = localStorage.getItem('startpage');
    }

    if (sessionStorage.getItem('startpage') == null) {
      sessionStorage.setItem('startpage', window.location.href);
      sessionstartpage = window.location.href;
    } else {
      sessionstartpage = sessionStorage.getItem('startpage');
    }
  } catch (e) {
    console.error(e);
  }

  return { userStartPage: userstartpage, sessionStartPage: sessionstartpage };
};

export const getTrafficSource = () => {
  if (isServer) return;
  var trafficSource = '';
  if (
    sessionStorage.getItem('TrafficSource') == null &&
    document.referrer.indexOf('vnrebates.net') < 1
  ) {
    trafficSource = document.referrer;
    sessionStorage.setItem('TrafficSource', trafficSource);
  } else if (sessionStorage.getItem('TrafficSource') != null) {
    trafficSource = sessionStorage.getItem('TrafficSource');
  }
  return trafficSource;
};

export const getGAId = () => {
  if (isServer) return;
  let gaId = '';
  try {
    gaId = sessionStorage.getItem('gaid');
    if (!gaId || gaId === '') {
      gaId = window.ga.getAll()[0].get('clientId');
    }
  } catch (e) {
    console.error(e);
  }
  return gaId;
};

export const getMetaDataClickEvent = () => {
  let sessionStartPage = '';
  let userStartPage = '';
  let clickEvents = [];

  try {
    sessionStartPage = sessionStorage?.getItem('session_start_page');
    userStartPage = localStorage?.getItem('user_start_page');
    clickEvents = localStorage?.getItem('clickEvents')?.toString() || '';
  } catch (e) {
    console.error(e);
  }

  return {
    trackingSessionStartPage: sessionStartPage,
    trackingUserStartPage: userStartPage,
    clickEvents,
  };
};

export const getSourceOfUser = (campaign, trafficSource) => {
  if (!campaign) {
    campaign = '';
  }
  if (!trafficSource) {
    trafficSource = '';
  }
  const traffic_source = trafficSource;
  let sourcelabel = campaign;
  let medium = campaign.match(/utm_medium=(.*)&/);
  let mediumlabel = '';
  if (medium != null) {
    mediumlabel = medium[1];
    sourcelabel = mediumlabel;
  } else if (campaign.match(/utm_medium=(.*)$/)) {
    medium = campaign.match(/utm_medium=(.*)$/);
    mediumlabel = medium[1];
    sourcelabel = mediumlabel;
  }

  if (campaign.includes('gclid=')) {
    sourcelabel = 'Google_Ads ' + mediumlabel;
  } else if (
    campaign.includes('fbads_trafic') ||
    campaign.includes('fb=') ||
    campaign.includes('fbads')
  ) {
    sourcelabel = 'Facebook_Ads ' + mediumlabel;
  } else if (traffic_source.includes('google')) {
    sourcelabel = 'Google_Organic';
  } else if (traffic_source.includes('facebook') || campaign.includes('fbclid')) {
    sourcelabel = 'Facebook_Organic ' + mediumlabel;
  } else if (campaign.includes('getresponse')) {
    sourcelabel = 'Email';
  }
  return sourcelabel;
};

const getMetaDataTrackingWhenRegister = () => {
  let userClientSessionId = '';
  let trafficSource = '';
  let campaignName = '';
  if (isServer) return {};

  try {
    userClientSessionId = localStorage.getItem('UserClientID'); //document.getElementById('UserClientID').value;
    trafficSource = sessionStorage.getItem('TrafficSource'); //document.getElementById('TrafficSource').value;
    campaignName = sessionStorage.getItem('CampaignName'); //document.getElementById('CampaignName').value;
  } catch (e) {
    console.error(e);
  }

  return {
    userClientSessionId,
    trafficSource,
    campaignName,
    sourceOfUser: getSourceOfUser(campaignName, trafficSource),
    gaId: getGAId(),
    ...getMetaStartPage(),
    ...getMetaDataClickEvent(),
  };
};

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
const Base64 = {
  btoa: (input) => {
    const str = input;
    let output = '';

    for (
      let block = 0, charCode, i = 0, map = chars;
      str.charAt(i | 0) || ((map = '='), i % 1);
      output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
    ) {
      charCode = str.charCodeAt((i += 3 / 4));

      if (charCode > 0xff) {
        throw new Error(
          "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
        );
      }

      block = (block << 8) | charCode;
    }

    return output;
  },

  atob: (input) => {
    const str = input.replace(/[=]+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (
      let bc = 0, bs = 0, buffer, i = 0;
      (buffer = str.charAt(i++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  },
};

const parseJwt = (token) => {
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    Base64.atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export {
  parseJwt,
  clearUserInfo,
  getHeaderAuthorization,
  getInfoUserLogin,
  getMetaDataTrackingWhenRegister,
};
