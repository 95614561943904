import { useEffect,useState } from "react";

export const useInAppBrowser = () => {
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);
  const [isFacebookInAppBrowser, setIsFacebookInAppBrowser] = useState(false);
  const [isInstagramInAppBrowser, setIsInstagramInAppBrowser] = useState(false);

  useEffect(() => {
    const { standalone } = window.navigator;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    const ua = navigator.userAgent || navigator.vendor || window.opera;

    if (ios) {
      if (!standalone && safari) {
        //browser
        setIsInAppBrowser(false);
      } else if (standalone && !safari) {
        //standalone
        setIsInAppBrowser(true);
      } else if (!standalone && !safari) {
        //uiwebview
        setIsInAppBrowser(true);
      }
    } else {
      //not iOS
      setIsInAppBrowser(false);
    }

    // FB in app browser
    if (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1) {
      setIsInAppBrowser(true);
      setIsFacebookInAppBrowser(true);
    }

    // Instagram in app browser
    if (ua.indexOf("Instagram") > -1) {
      setIsInAppBrowser(true);
      setIsInstagramInAppBrowser(true);
    }
  }, []);

  return [isInAppBrowser, isFacebookInAppBrowser, isInstagramInAppBrowser];
};
