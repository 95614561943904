import '../src/styles/styles.scss';

// import AppScriptDom from '@components/AppScriptDom';
const LoginCard = dynamic(() => import("@components/LoginCard"));
import Startup from '@components/Layout/Startup';
// import LoginCard from '@components/LoginCard';
import GoogleOnetap from '@components/LoginCard/SocialLogin/googleOneTap';
import { trackingClickEvents, trackingUserStartPage } from '@utils/trackingUtil';
import FontFaceObserver from 'fontfaceobserver';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import SEO from '../next-seo.config';
import { useStore } from '../src/states/store';

const HTML = ({ Component, pageProps, ...props }) => {
  const store = useStore(props.initialReduxState);

  useEffect(() => {
    loadFonts();
    try {
      document.onclick = (e) => trackingClickEvents(e);
      trackingUserStartPage();
    } catch (e) {}
  }, []);

  //Load fonts
  const loadFonts = () => {
    const element = document.documentElement;
    element.classList.add('fonts-loading');
    setTimeout(() => {
      const element = document.documentElement;
      element.classList.remove('fonts-loading');
      element.classList.add('fonts-loaded');
    }, 500);
    const fontNunito = new FontFaceObserver('Nunito');
    const fontNunitoReg = new FontFaceObserver('Nunito-Regular');
    Promise.all([fontNunito.load(), fontNunitoReg.load()])
      .then(function () {
        element.classList.remove('fonts-loading');
        element.classList.add('fonts-loaded');
      })
      .catch(function () {
        element.classList.remove('fonts-loading');
        element.classList.add('fonts-failed');
      });
  };

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        {/* <link rel="preload" href="/static/fonts/Nunito-Bold.ttf" as="font" type="font/ttf"></link>
        <link rel="preload" href="/static/fonts/Nunito-Regular.ttf" as="font" type="font/ttf"></link> */}
        <meta
          name="google-site-verification"
          content="Xsa1TdOHTGQUK-8aHvbZgA0Q-Z1VzSS1xks7Bppp7N8"
        />
        <meta
          name="facebook-domain-verification"
          key="facebook-domain-verification"
          content="9rawctehnii9j5sonw54xc72gp9upl"
        ></meta>
        <meta name="google-site-verification" content="wnKgiPXgdz1Iaf8bfGUzdlE1GIXgjtN7VdmHOy0mv5s" />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              @media only screen and (max-width: 475px) {
                body {
                  font-size: 20px
                }
                #__next {
                    max-width: 100vw;
                    font-size: 16px;
                }
                #vnrPostDetail {
                  font-size: 14px;
                }
              }
              `,
          }}
        ></style>
        <noscript>
          <style
            dangerouslySetInnerHTML={{
              __html: `body {
              visibility: unset!important;
            }`,
            }}
          ></style>
        </noscript>
      </Head>

      <Provider store={store}>
        <Startup>
          <div className="app-layout">
            <GoogleOnetap />
            <DefaultSeo {...SEO} />
            <Component {...pageProps} />
            {/* <AppScriptDom sponsorLinks={[]} /> */}
          </div>
          <LoginCard />
        </Startup>
      </Provider>
    </>
  );
};

export default HTML;
