import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  revenueTotal: undefined,
  expenditureTotal: undefined,
  expenditureRevenueRatio: undefined,
  rebateTime: undefined,
  status: undefined,
  description: undefined,
  userId: undefined,
};

export const entityRebateMonthly = {
  name: 'entityRebateMonthly',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
