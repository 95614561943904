export const requiredRule = {
  required: true,
  message: 'Vui lòng nhập',
};

export const validateEmail = (email) => {
  const emailReg = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  
  return emailReg.test(email);
}

export const validatePhoneNumber = (number) => {
  if (!number || number === '') return false;
  
  const num = number.replaceAll(/\s/g,'')
  return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(num);
}