import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  postId: undefined,
  postUrl: undefined,
  content: undefined,
  parentCommentId: undefined,
  status: undefined,
  ownerId: undefined,
  ownerAvatarUrl: undefined,
  ownerName: undefined,
  levelComment: undefined,
  authorName: undefined,
  createdDate: undefined,
  postAuthorEmail: undefined
};

export const entityComment = {
  name: 'entityComment',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
