import React from "react";
import LazyLoad from "react-lazyload";
export const ImageThumbnailRatio = ({
  ratio = "16:9",
  src = "",
  children = "",
  additionStyle = {}
}) => {
  const [width, height] = ratio.split(":");
  const percentage = (parseInt(height) / parseInt(width)) * 100;
  const style = {
    backgroundImage: `url('${src}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingTop: `${percentage}%`,
    marginTop: `${-percentage}%`,
    backgroundRepeat: "no-repeat",
    ...additionStyle
  };
  return (
    <LazyLoad style={{ paddingTop: `${percentage}%` }}>
      <div style={style}>
        {children}
      </div>
    </LazyLoad>
  );
};
