import { request } from '../utils/request';

export const getBrokerDatas = () =>
  request({
    url: '/broker-data',
    method: 'GET',
  });

export const getAllBrokerData = () =>
  request({
    url: '/broker-data/getAll',
    method: 'GET',
    params: {},
    data: {},
  });

export const searchBrokerData = params =>
  request({
    url: '/broker-data/search',
    method: 'GET',
    params,
  });

export const deleteBrokerData = id =>
  request({
    url: `/broker-data/${id}`,
    method: 'DELETE',
  });

export const createBrokerData = brokerInfo =>
  request({
    url: '/broker-data/create',
    method: 'POST',
    data: brokerInfo,
  });

export const updateBrokerData = brokerInfo =>
  request({
    url: '/broker-data/update',
    method: 'PUT',
    data: brokerInfo,
  });

export const getHistoriesChange = ({ id, page, size }) =>
  request({
    url: `/broker-data/historiesChange/${id}`,
    method: 'GET',
    params: { page, size },
  });

export const importBroker = (data) => 
  request({
    url: '/broker-data/import',
    method: 'POST',
    data: data,
  });