import { request } from '../utils/request';

export const getMonthBonus = ({ page = 0, size = 10 }) =>
  request({
    url: '/month-bonus',
    method: 'GET',
    params: { page, size },
  });

export const createMonthBonus = (monthBonus) =>
  request({
    url: '/month-bonus',
    method: 'POST',
    data: monthBonus,
  });

export const updateMonthBonus = (monthBonus) =>
  request({
    url: '/month-bonus',
    method: 'PUT',
    data: monthBonus,
  });

export const getBonusByMonth = ({ date } = {}) =>
  request({
    url: `/month-bonus/getByMonth`,
    method: 'GET',
    params: { date },
    data: {},
  });

export const getHistoriesChange = ({ id, page, size }) =>
  request({
    url: `/month-bonus/${id}/histories`,
    method: 'GET',
    params: { page, size },
  });
