import { request } from '../utils/request';

export const login = loginRequest =>
  request({
    url: '/auth/authenticate',
    method: 'POST',
    data: loginRequest,
  });

export const signup = signupRequest =>
  request({
    url: '/auth/register',
    method: 'POST',
    data: signupRequest,
  });

export const socialLogin = data =>
  request({
    url: '/auth/social',
    method: 'POST',
    data,
  });

export const forgotPassword = params =>
  request({
    url: '/auth/reset-password',
    method: 'POST',
    params,
  });

export const validResetToken = params =>
  request({
    url: '/auth/reset-password/confirm',
    method: 'GET',
    params,
  });

export const savePassword = data =>
  request({
    url: '/auth/reset-password/save',
    method: 'POST',
    data,
  });

export const emailVerify = params =>
  request({
    url: '/auth/email-verify',
    method: 'GET',
    params,
  });
