import { getSupport } from '../../services/user.service';
import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  name: undefined,
  email: undefined,
  phone: undefined,
  phoneCode: undefined,
  province: undefined,
  password: undefined,
  imageUrl: undefined,
  status: undefined,
  provider: undefined,
  providerId: undefined,
  emailVerified: undefined,
  mustVerifyInfo: undefined,
  userClientSessionId: undefined,
  trafficSource: undefined,
  campaignName: undefined,
  gaId: undefined,
  customerCareColor: undefined,
  customerCareUserId: undefined,
  createdDate: undefined,
  userStartPage: undefined,
  sessionStartPage: undefined,
  isCustomerCare: undefined,
  expenditureRevenueRatio: undefined,
  adminBrokerId: undefined,
  wpUserId: undefined,
  isNoFollowCustomer: undefined,
  isTodayAppointment: undefined,
  customerCareModName: undefined,
  agencyId: undefined,
  market: undefined,
  services: undefined,
  productPurchased: undefined,
  isCustomerCareActive: undefined,
  timeSetPhoneNumber: undefined,
  lastTimeVisit: undefined,
  telegramName: undefined
};

export const entityUser = {
  name: 'entityUser',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: dispatch => ({
    getCustomerCares: async () => {
      const { data } = await getSupport();
      const customerCares = data.content.reduce((rs, curr) => {
        curr.isCustomerCare = true;
        rs.push(curr);
        return rs;
      }, []);
      dispatch.entityUser.saves(customerCares);
    },
  }),
};
