import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  content: undefined,
  parentCommentId: undefined,
  status: undefined,
  ownerId: undefined,
  ownerAvatarUrl: undefined,
  ownerName: undefined,
  authorName: undefined,
  createdDate: undefined,
  images: undefined,
  totalReplies: 0
};

export const entityTraderComment = {
  name: 'entityTraderComment',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
