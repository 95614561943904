import React from 'react';

const IconTelegram = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
      className={className}
    >
      <circle cx="24.995" cy="24" r="23" stroke="#fff" strokeWidth="2" />
      <path
        fill="#fff"
        d="M21.412 27.181l-.397 5.584c.568 0 .814-.244 1.11-.537l2.662-2.545 5.518 4.041c1.012.564 1.725.267 1.998-.931l3.622-16.972.001-.001c.321-1.496-.54-2.081-1.527-1.714l-21.29 8.151c-1.453.564-1.43 1.374-.247 1.741l5.443 1.693 12.643-7.911c.595-.394 1.136-.176.691.218l-10.227 9.183z"
      />
    </svg>
  );
};

export default IconTelegram;
