import produce from 'immer';

import { LOGIN_STATUS } from '../../constants';
import { getCurrentUser } from '../../services/user.service';
import { getInfoUserLogin } from '../../utils/auth-helper';
import { triggerEventImpressionLoginPopup } from '../../utils/gtmUtil';

export const app = {
  name: 'app',
  state: {
    auth: {
      signed: LOGIN_STATUS.WAITING_CHECK,
      isShowPopupLogin: false,
      positionLogin: '',
      redirectUrl: '/dashboard',
      afterLoginSuccess: '',
    },
    user: {},
    popup: {},
    fetchingUser: false,
    notificationNumber: 1,
    notifications: [],
    menuLeftCollapseStatus: false,
    isSearchReferAccount: false,
    livestreamVideoId: ''
  },
  reducers: {
    showPopupLogin: produce((state, options = {}) => {
      const { redirectUrl, positionLogin = '' } = options;
      if (redirectUrl !== undefined) {
        state.auth.redirectUrl = redirectUrl;
      }
      state.auth.positionLogin = positionLogin;
      state.auth.isShowPopupLogin = true;
      state.auth.afterLoginSuccess = options.afterLoginSuccess;
      triggerEventImpressionLoginPopup({ positionEvent: positionLogin });
    }),
    hiddenPopupLogin: produce(state => {
      state.auth.positionLogin = '';
      state.auth.isShowPopupLogin = false;
    }),
    setLoginUser: produce((state, user = {}) => {
      state.user = user;
    }),
    showPopup: produce((state, popupId = '', data = {}) => {
      if (!state.popup[popupId]) {
        state.popup[popupId] = { visible: false, data: {} };
      }
      state.popup[popupId].visible = true;
      state.popup[popupId].data = data;
    }),
    hiddenPopup: produce((state, popupId = '', data = {}) => {
      if (!state.popup[popupId]) {
        state.popup[popupId] = {};
      }
      state.popup[popupId].visible = false;
      state.popup[popupId].data = data;
    }),
    setFetchingUser: produce((state, status) => {
      state.fetchingUser = status;
    }),
    setSigned: produce((state, status) => {
      state.auth.signed = status;
    }),
    increaseNotificationNumber: produce((state) => {
      state.notificationNumber = state.notificationNumber + 1;
      return state;
    }),
    addNotifications: produce((state, notification) => {
      state.notifications.push(notification);
      return state;
    }),
    toggleMenuLeftCollapseStatus: produce(state => {
      state.menuLeftCollapseStatus = !state.menuLeftCollapseStatus;
      return state;
    }),
    toggleShowReferAccount: produce((state) => {
      state.isSearchReferAccount = !state.isSearchReferAccount;
      return state;
    }),
    setLivestreamVideoId: produce((state, id) => {
      state.livestreamVideoId = id;
      return state;
    }),
  },
  effects: dispatch => ({
    initAuth: (data, rootState) => {
      const dispatchApp = dispatch.app;
      const fetchUserInfo = async () => {
        dispatchApp.setSigned(LOGIN_STATUS.WAITING_CHECK);
        try {
          const res = await getCurrentUser();
          dispatchApp.setLoginUser(res.data);
          dispatchApp.setSigned(LOGIN_STATUS.LOGGED_IN);
        } catch (e) {
          console.error(e);
          dispatchApp.setLoginUser({});
          dispatchApp.setSigned(LOGIN_STATUS.NOT_LOGGED_IN);
        }
      };
      try {
        const { token } = getInfoUserLogin();
        const { user: currentUser, isUserFetched } = rootState.app;
        if (currentUser && currentUser.id && isUserFetched) {
          // logined
          dispatchApp.setSigned(LOGIN_STATUS.LOGGED_IN);
        } else if (!token) {
          dispatchApp.setSigned(LOGIN_STATUS.NOT_LOGGED_IN);
          dispatchApp.setLoginUser({});
        } else {
          dispatchApp.setFetchingUser(true);
          fetchUserInfo();
        }
      } finally {
        dispatchApp.setFetchingUser(false);
      }
    },
  }),
};
