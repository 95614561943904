const getAcademyParts = (tradingAcademySlug) => {
  if (!tradingAcademySlug) return [];

  const partByAcademy = {
    'hoc-dau-tu-forex-co-ban': [
      { 
        "code": "CƠ BẢN VỀ THỊ TRƯỜNG FOREX", 
        "name": "CƠ BẢN VỀ THỊ TRƯỜNG FOREX",
        order: 1
      },
      { 
        "code": "PHÂN TÍCH PRICE ACTION CƠ BẢN", 
        "name": "PHÂN TÍCH PRICE ACTION CƠ BẢN",
        order: 2
      }, 
      { 
        "code": "HƯỚNG DẪN GIAO DỊCH", 
        "name": "HƯỚNG DẪN GIAO DỊCH",
        order: 3
      }
    ],
    'hoc-dau-tu-forex-nang-cao': [
      { 
        "code": "TÂM LÝ GIAO DỊCH", 
        "name": "TÂM LÝ GIAO DỊCH",
        order: 1
      },
      { 
        "code": "TƯ DUY GIAO DỊCH ĐÚNG", 
        "name": "TƯ DUY GIAO DỊCH ĐÚNG",
        order: 2
      }, 
      { 
        "code": "KẾ HOẠCH GIAO DỊCH VÀ QUẢN LÝ VỐN", 
        "name": "KẾ HOẠCH GIAO DỊCH VÀ QUẢN LÝ VỐN",
        order: 3
      },
      { 
        "code": "KIẾN THỨC VỀ FOREX BROKER", 
        "name": "KIẾN THỨC VỀ FOREX BROKER",
        order: 4
      }
    ],
    'hoc-price-action-a-z': [
      { 
        "code": "HỌC PRICE ACTION A-Z", 
        "name": "HỌC PRICE ACTION A-Z",
        order: 1
      }
    ],
    'khoa-hoc-price-action-chuyen-sau': [
      { 
        "code": "PRICE ACTION CHUYÊN SÂU", 
        "name": "PRICE ACTION CHUYÊN SÂU",
        order: 1
      }
    ]
  }

  if (tradingAcademySlug === 'ALL') {
    return Object.values(partByAcademy).flat();
  }

  if (tradingAcademySlug === 'ALL_SLUG') {
    return Object.keys(partByAcademy);
  }
  
  return partByAcademy[tradingAcademySlug];
};

module.exports = { getAcademyParts };
