import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  createdBy: undefined,
  createdDate: undefined,
  lastModifiedBy: undefined,
  lastModifiedDate: undefined,
  expenditure: undefined,
  lotGold: undefined,
  paid: undefined,
  paidDate: undefined,
  rebateTime: undefined,
  revenue: undefined,
  brokerAccountId: undefined,
  userId: undefined,
};

export const entityRebate = {
  name: 'entityRebate',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
