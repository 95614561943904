import qs from 'qs';
import { DOMAIN } from 'src/constants';

import { request } from '../utils/request';

export const getCurrentUser = () =>
  request({
    url: '/user/me',
    method: 'GET',
  });

export const getUsers = ({ page, size, ...dataSearch }) =>
  request({
    url: '/user',
    method: 'GET',
    params: {
      page,
      size,
      ...dataSearch,
      ibStatus: dataSearch.ibStatus ? dataSearch.ibStatus.join(',') : [],
      customerCare: dataSearch.customerCare ? dataSearch.customerCare.join(',') : [],
      ids: dataSearch.ids ? dataSearch.ids.join(',') : [],
      isDisableSort: dataSearch.isDisableSort ? dataSearch.isDisableSort : false,
    },
  });

export const updateUserInfo = (data) =>
  request({
    url: '/user',
    method: 'POST',
    data,
  });

export const updateInfo = (userId, data) =>
  request({
    url: `/user/${userId}/updateInfo`,
    method: 'PUT',
    data,
  });

export const getCustomerCares = () =>
  request({
    url: '/user/getCustomerCares',
    method: 'PUT',
    params: {},
    data: {},
  });

export const getSupport = () =>
  request({
    url: '/user/getSupport',
    method: 'PUT',
    params: {},
    data: {},
  });

export const buyBookPriceActionAdvance = () =>
  request({
    url: '/user/buyBookPriceActionAdvance',
    method: 'GET',
    params: {},
    data: {},
  });

export const buyPDFPriceActionAdvance = () =>
  request({
    url: '/user/buyPDFPriceActionAdvance',
    method: 'GET',
    params: {},
    data: {},
  });

export const getUserByListId = ({ ids } = {}) =>
  request({
    url: '/user/getByListId',
    method: 'GET',
    params: { ids },
    data: {},
  });

export const updateAdminBroker = ({ userId, brokerId } = {}) =>
  request({
    url: `/user/${userId}/updateAdminBroker`,
    method: 'PUT',
    params: { brokerId },
    data: {},
  });

export const updateWpUserId = ({ userId, wpUserId } = {}) =>
  request({
    url: `/user/${userId}/updateWpUserId`,
    method: 'PUT',
    params: { wpUserId },
    data: {},
  });

export const createUserInWP = (user) =>
  request({
    host: `${DOMAIN}/wp-json/api/v1`,
    url: '/createContributor',
    method: 'POST',
    data: {
      username: user.email,
      password: user.email + '!@#$%^',
      email: user.email,
    },
  });

export const addContact = ({ username, email, message }) =>
  request({
    url: '/user/addContact',
    method: 'POST',
    params: {
      username,
      email,
      message,
    },
  });

export const getByEmail = ({ email }) =>
  request({
    url: '/user/getByEmail',
    method: 'GET',
    params: { email },
  });

export const getUsersByRole = ({ userId }) =>
  request({
    url: `/user/${userId}/roles`,
    method: 'GET',
    params: {},
    data: {},
  });

export const getUsersByEmailAndHaveNotInRole = ({ page, size = 5, roleId, email }) =>
  request({
    url: '/user/getUsersByEmailAndHaveNotInRole',
    method: 'GET',
    params: {
      roleId,
      page,
      size,
      email,
    },
  });

export const findUser = ({ page, ...searchParams }) =>
  request({
    url: '/user/searching',
    method: 'GET',
    params: {
      page,
      ...searchParams,
    },
    data: {},
  });

export const searchByEmail = ({ page, ...searchParams }) =>
  request({
    url: '/user/searchByEmail',
    method: 'GET',
    params: {
      page,
      ...searchParams,
    },
    data: {},
  });

export const getUsersByRoleId = ({ roleId }) =>
  request({
    url: '/user/getUsersByRoleId',
    method: 'GET',
    params: { roleId },
    data: {},
  });

export const getUserRoles = (userId) =>
  request({
    url: '/user/getUserRoles',
    method: 'GET',
    params: { userId: userId },
    data: {},
  });

export const addUsersToRole = ({ roleId, userIds }) =>
  request({
    url: '/user/addUsersToRole',
    method: 'PUT',
    params: {
      roleId,
      userIds,
    },
    data: {},
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

export const removeUsersOfRole = ({ roleId, userIds }) =>
  request({
    url: '/user/removeUsersOfRole',
    method: 'DELETE',
    params: { roleId, userIds },
    data: {},
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

export const getUsersByPermissionId = ({ permissionId, page, size }) =>
  request({
    url: '/user/getUsersByPermissionId',
    method: 'GET',
    params: { permissionId, page, size },
    data: {},
  });

export const updateUserProfile = (params) =>
  request({
    url: '/user/updateUserProfile',
    method: 'POST',
    params: params,
    data: {},
  });

export const getUserCollaborator = () =>
  request({
    url: '/user/getUserCollaborator',
    method: 'GET',
    params: {},
    data: {},
  });

export const getAllUsers = () =>
  request({
    url: '/user/all',
    method: 'GET',
  });

export const updateAvatar = ({ userId, userAvatar }) =>
  request({
    url: `/user/${userId}/updateAvatar`,
    method: 'PUT',
    params: { userAvatar: userAvatar },
  });

export const updateFollowStatus = ({ userId, followStatus, phone }) =>
  request({
    url: `/user/${userId}/updateFollowStatus`,
    method: 'PUT',
    params: { followStatus: followStatus, phone },
  });

export const updateCustomerMod = ({ userId, customerCareModName }) =>
  request({
    url: `/user/${userId}/updateCustomerMod`,
    method: 'PUT',
    params: { customerCareModName: customerCareModName },
  });

export const updateCustomerCare = ({ userId, customerCareId }) =>
  request({
    url: `/user/${userId}/updateCustomerCare`,
    method: 'PUT',
    params: { customerCareId },
  });

export const getUserPromoCode = ({ userId, type }) =>
  request({
    url: `/user/getUserPromoCode/${userId}`,
    method: 'GET',
    params: { type: type },
  });

export const getUserNameByRefCode = ({ refCode }) =>
  request({
    url: `/user/getUserNameByRefCode`,
    method: 'GET',
    params: { refCode: refCode },
  });

export const getUsersToSendStatisticEmail = ({ page = 0, size = 10 } = {}) =>
  request({
    url: `/user/getUsersToSendStatisticEmail`,
    method: 'GET',
    params: { page, size },
  });

export const getUsersToSendSignalGroupEmail = ({ page = 0, size = 10 } = {}) =>
  request({
    url: `/user/getUsersToSendSignalGroupEmail`,
    method: 'GET',
    params: { page, size },
  });

export const getUsersToSendAdminCustomEmail = ({ page = 0, size = 10 } = {}) =>
  request({
    url: `/user/getUsersToSendAdminCustomEmail`,
    method: 'GET',
    params: { page, size },
  });

export const checkValidAgencyCode = (code = '') =>
  request({
    url: `/user/checkValidAgencyCode`,
    method: 'GET',
    params: { agencyCode: code },
  });

export const registerAgency = ({ userId }) =>
  request({
    url: '/user/registerAgency',
    method: 'PUT',
    params: { userId },
    data: {},
  });

export const getUserOwnAgencyRefCode = (userId) =>
  request({
    url: `/user/getUserOwnAgencyRefCode/${userId}`,
    method: 'GET',
  });

export const updateUserAgency = ({ userId, agencyEmail, agencyCode }) =>
  request({
    url: `/user/${userId}/updateUserAgency`,
    method: 'PUT',
    params: { agencyEmail: agencyEmail, agencyCode: agencyCode },
    data: {},
  });

export const removeUserAgency = ({ userId }) =>
  request({
    url: `/user/${userId}/removeUserAgency`,
    method: 'PUT',
    data: {},
  });

export const getAllActiveAgency = ({ email, page, size }) =>
  request({
    url: '/user/getAllActiveAgency',
    params: { email, page, size },
    method: 'GET',
  });

export const adminUpdateUserAgencyStatus = ({ userId, status }) =>
  request({
    url: '/user/adminUpdateUserAgencyStatus',
    method: 'PUT',
    params: { userId: userId, status: status },
    data: {},
  });

export const removeAllCustomerCareById = ({ id, page = 0, size = 1000 } = {}) =>
  request({
    url: `/user/removeAllCustomerCareById`,
    method: 'GET',
    params: { id, page, size },
  });

export const updateUserProductPurchased = ({ userId, productPurchased }) =>
  request({
    url: `/user/${userId}/updateUserProductPurchased`,
    method: 'PUT',
    params: { productPurchased: productPurchased },
    data: {},
  });

export const updateCustomerCareStatus = ({ userId, status }) =>
  request({
    url: `/user/${userId}/updateCustomerCareStatus`,
    method: 'PUT',
    params: { status: status },
    data: {},
  });

export const getAllUserEmail = () =>
  request({
    url: '/user/getAllUserEmail',
    method: 'GET',
    params: {},
    data: {},
  });

export const updateTelegramName = ({ userId, telegramName }) =>
  request({
    url: `/user/updateTelegramName`,
    method: 'PUT',
    params: { userId: userId, telegramName: telegramName },
  });
