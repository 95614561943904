import { localStorageUtil, sessionStorageUtil } from './storageUtil';

const TAGS_NO_TRACKING = ['INPUT'];

const saveDataTrackingClickEvents = (elements) => {
  let dataTracking = JSON.parse(localStorageUtil.getItem('clickEvents') || '[]');

  if (dataTracking.length === 0) {
    localStorageUtil.setItem('clickEvents', JSON.stringify(elements));
  } else {
    if (dataTracking.length === 20) {
      dataTracking.shift();
    }

    dataTracking.push(...elements);
    localStorageUtil.setItem('clickEvents', JSON.stringify(dataTracking));
  }
};

const getStep = () => {
  const clickEvents = JSON.parse(localStorageUtil.getItem('clickEvents') || '[]');

  if (clickEvents.length === 0) {
    return 1;
  }

  return clickEvents[clickEvents.length - 1].step + 1;
};

export const trackingClickEvents = (e) => {
  const token = localStorageUtil.getItem('app.token');
  if (token) {
    return;
  }

  let elements = new Array();
  let element = {};
  const clickedElement = window.event ? window.event.srcElement : e.target;
  
  let node = clickedElement;
  if (node.tagName && TAGS_NO_TRACKING.includes(node.tagName)) {
    return;
  }
  let nodeTagA  = node;
  let gtmEvent = undefined;
  while (node.parentNode) {
    if (!nodeTagA && node.tagName === 'A') {
      nodeTagA = node;
    }
    if (!gtmEvent && node.getAttribute("data-gtm-event")) {
      gtmEvent = node.getAttribute("data-gtm-event");
    }
    node = node.parentNode;
  }

  if (!nodeTagA) {
    element = {
      tag: clickedElement.tagName,
      content: clickedElement.innerHTML,
      href: clickedElement.href,
      currentUrl: window.location.href,
      timeClick: new Date().getTime(),
      step: getStep(),
    };
  } else {
    element = {
      tag: nodeTagA.tagName,
      href: nodeTagA.href,
      currentUrl: window.location.href,
      timeClick: new Date().getTime(),
      step: getStep(),
    };
  }
  element.gtmEvent = gtmEvent;
  elements.push(element);
  saveDataTrackingClickEvents(elements);
};

export const trackingUserStartPage = () => {
  const dataSessionStartPage = sessionStorageUtil.getItem('session_start_page');
  const dataUserStartPage = localStorageUtil.getItem('user_start_page');

  if (!dataSessionStartPage) {
    sessionStorageUtil.setItem('session_start_page', window.location.href);
  }

  if (!dataUserStartPage) {
    localStorageUtil.setItem('user_start_page', window.location.href);
  }
};
