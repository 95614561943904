import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  email: undefined,
  status: undefined,
  verifyMessage: undefined,
  userId: undefined,
};

export const entityPaymentMethod = {
  name: 'entityPaymentMethod',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
