import ModalHook, { useModal } from '@components/ModalHook';
import VnrLink from '@components/VnrLink';
import { Col,Row } from 'antd';
import React from 'react';

import styles from "./PopupUserSentInformation.module.scss";

export const MODAL_ID = 'POPUP_USER_SENT_INFORMATION';
const thankYouImage = '/images/image 31.png';

const PopupUserSentInformation = () => {
  const [modalData,, hidePopup] = useModal(MODAL_ID);

  return (
    <ModalHook 
      id={MODAL_ID} 
      onCancel={hidePopup}
      title={
        <span  className={styles.title}>
          Thành công!
        </span>
      }
      className={styles['popup-perform-target-reward']}
      style={{top: 80}}
      width={480}
    >
      <div className={styles['content-wrapper']}>
        <Row className={styles['header']} align="middle">
          <Col span={24} className={styles.imageWrapper}>
            <img src={thankYouImage} alt="thankyou" />
          </Col>
          <Col className={styles['header-requirement-text']} span={24}>
          VnRebates đã nhận được yêu cầu Hoàn phí từ bạn và sẽ phản hồi qua mail sớm nhất. Trong khi đó, bạn có thể chat với support để xúc tiến nhanh hơn tại đây
          </Col>
          
          <Col className={styles.supportText} span={24}>

          <div className={styles.itemAction}>
          <VnrLink to="https://m.me/vnrebates" className={styles.rebateRate}>
          <img src="/images/messenger.svg" />
          
          Messenger
          </VnrLink>
          <VnrLink to="https://t.me/vnrebatessupport" className={styles.rebateRate}>
          <img src="/images/telegram-form.svg" />
          Telegram
          </VnrLink>
        </div></Col>
        </Row>
      </div>
    </ModalHook>
  )
}

export default PopupUserSentInformation;