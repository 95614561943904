import produce from 'immer';

const defaultState = {
  visibleModalManageUserRolePermission: false,
};
//  model
export const pageManageUserRolePermission = {
  name: 'pageManageUserRolePermission',
  state: {
    ...defaultState,
  },

  reducers: {
    initState: produce(state => {
      state = defaultState;
      return state;
    }),
    saveVisibleModalManageUserRolePermission: produce((state, visibleModal) => {
      state.visibleModalManageUserRolePermission = visibleModal;
      return state;
    }),
  },
};
