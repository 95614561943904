import axios from 'axios';
import { API_HOST } from 'src/constants';

import { clearUserInfo, getHeaderAuthorization } from './auth-helper';


export const request = async ({
  url = "",
  method = "get",
  params,
  data,
  headers = {},
  ...props
}) => {
  try {
    const result = await axios({
      url: `${API_HOST}/api/v1${url}`,
      method,
      data,
      params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        ...headers,
        ...getHeaderAuthorization(),
      },
      ...props,
    });
    return result;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status === 401) {
      clearUserInfo();
    }
    throw error;
  }
};

export const requestDownload = async ({
  defaultFileName = "fileDownload",
  headers = {},
  getBlob = false,
  ...props
}) => {
  const res = await request({
    ...props,
    responseType: "blob",
    headers: {
      Accept: "*/*",
      ...headers,
    },
  });

  if (getBlob) {
    return new Blob([res.data]);
  }

  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement("a");
  link.href = url;
  let fileName = defaultFileName;
  if (res.headers && res.headers["content-disposition"]) {
    const contentDisposition = res.headers["content-disposition"];
    const indexFileName = contentDisposition.indexOf("filename=");
    if (indexFileName >= 0) {
      fileName = contentDisposition.substring(
        indexFileName,
        contentDisposition.length
      );
      fileName = fileName.replace('filename="', '');
      fileName = fileName.replace('filename=', '');
    }
    let indexLastFilename = fileName.indexOf('"');
    indexLastFilename =
      indexLastFilename === -1 ? fileName.length : indexLastFilename;
    fileName = fileName.substring(0, indexLastFilename);
  }
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  return res;
};
