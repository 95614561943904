import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  userId: undefined,
  metaKey: undefined,
  metaValue: undefined,
  description: undefined,
  fieldType: undefined,
};

export const entityUserMeta = {
  name: 'entityUserMeta',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
};
