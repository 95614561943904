import {saveCommissionSiteMonthly} from '@services/commisisonSiteMonthly.service'
import dayjs from 'dayjs';
import produce from 'immer';

import {
  getRebateBrokerMonthlyByMonth,
  saveRebateBrokerMonthly,
} from '../../services/rebateBrokerMonthly.service';
import {
  getRebateMonthlyByTime,
  updateRebateMonthlyStatus,
} from '../../services/rebateMonthly.service';
import {
  getRebateSiteMonthlyByMonth,
  saveRebateSiteMonthly,
} from '../../services/rebateSiteMonthly.service';
export const exportAvenue = {
  name: 'exportAvenue',
  state: {
    month: undefined,
  },
  reducers: {
    setData: produce((state, payload = {}) => {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    }),
  },
  effects: (dispatch) => ({
    async loadRebateMonthlyByTime(payload) {
      const avenueDataUserRes = await getRebateMonthlyByTime(payload);
      if (avenueDataUserRes) {
        const { content, metaEntity } = avenueDataUserRes.data;

        if (content) {
          dispatch.entityRebateMonthly.saves(content);
        }

        if (metaEntity) {
          dispatch.entityUser.saves(metaEntity.users);
        }
      }
    },
    async saveRebateMonthlyStatus(payload) {
      const res = await updateRebateMonthlyStatus(payload);
      if (res.data) {
        dispatch.entityRebateMonthly.saves([res.data]);
      }
    },
    async loadRebateSiteMonthlyByTime(payload, state) {
      const result = Object.values(state.entityRebateSiteMonthly.ids).find(
        (item) => dayjs(item.rebateTime).startOf('month').format('YYYY-MM-DD') === payload.month
      );

      if (result) return result;

      const avenueDataUserRes = await getRebateSiteMonthlyByMonth(payload);
      if (avenueDataUserRes) {
        const { content } = avenueDataUserRes.data;

        if (content) {
          dispatch.entityRebateSiteMonthly.saves(content);
        }

        return content;
      }
    },
    async saveRebateSiteMonthlyStatus(payload) {
      const res = await saveRebateSiteMonthly(payload);
      if (res.data) {
        dispatch.entityRebateSiteMonthly.saves([res.data]);
      }
    },
    async saveCommissionSiteMonthlyStatus(payload) {
      const res = await saveCommissionSiteMonthly(payload);
      if (res.data) {
        console.log('Cập nhật commission thành công!')
      }
    },
    async loadRebateBrokerMonthlyByTime(payload) {
      const avenueDataUserRes = await getRebateBrokerMonthlyByMonth(payload);
      if (avenueDataUserRes) {
        const { content } = avenueDataUserRes.data;

        if (content) {
          dispatch.entityRebateBrokerMonthly.saves(content);
        }

        return content;
      }
    },
    async saveRebateBrokerMonthlyStatus(payload) {
      const res = await saveRebateBrokerMonthly(payload);
      if (res.data) {
        dispatch.entityRebateBrokerMonthly.saves([res.data]);
      }
    },
  }),
};
