const defaultSEO = {
  title: 'VnRebates - Hoàn phí mọi giao dịch tài chính của bạn',
  description:
    'VnRebates cung cấp Kiến thức chuyên sâu về đầu tư Forex, khoá học Price Action từ cơ bản đến nâng cao, Dịch Vụ Rebates (Hoàn Phí) Uy tín cho Trader.',
  author: '@XuanAnh',
  image: 'https://vnrebates.net/static/vnrebates_banner-c132a85ea7f155c941fa35996682f115.jpg',

  openGraph: {
    title: 'VnRebates - Hoàn phí mọi giao dịch tài chính của bạn',
    description:
      'VnRebates cung cấp Kiến thức chuyên sâu về đầu tư Forex, khoá học Price Action từ cơ bản đến nâng cao, Dịch Vụ Rebates (Hoàn Phí) Uy tín cho Trader.',
    locale: 'vi_VN',
    type: 'blog',
    site_name: 'Vnrebates',
    images: [
      {
        url: 'https://vnrebates.net/static/vnrebates_banner-c132a85ea7f155c941fa35996682f115.jpg',
        secureUrl:
          'https://vnrebates.net/static/vnrebates_banner-c132a85ea7f155c941fa35996682f115.jpg',
        alt: 'vnrBanner',
      },
    ],
    article: {
      publisher: 'https://www.facebook.com/Vnrebates/',
    },
  },
  facebook: {
    appId: '420693788621955',
  },
};

export default defaultSEO;
