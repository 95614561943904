import { request } from '../utils/request';

export const getBrokers = () =>
  request({
    url: '/broker',
    method: 'GET',
  });

export const getAllBroker = () =>
  request({
    url: '/broker/getAll',
    method: 'GET',
    params: {},
    data: {},
  });

export const searchBroker = params =>
  request({
    url: '/broker/search',
    method: 'GET',
    params,
  });

export const deleteBroker = id =>
  request({
    url: `/broker/${id}`,
    method: 'DELETE',
  });

export const createBroker = brokerInfo =>
  request({
    url: '/broker/create',
    method: 'POST',
    data: brokerInfo,
  });

export const updateBroker = brokerInfo =>
  request({
    url: '/broker/update',
    method: 'PUT',
    data: brokerInfo,
  });

export const getHistoriesChange = ({ id, page, size }) =>
  request({
    url: `/broker/historiesChange/${id}`,
    method: 'GET',
    params: { page, size },
  });
