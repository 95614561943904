import LoginWhite from '@components/Icons/loginWhite';
import { Button, Checkbox, Form, Input, Row, Typography } from 'antd';
import Router from 'next/router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { login } from '../../../services/auth.service';
import { handleAfterLoginComplete } from '../../Auth';
import styles from './LoginForm.module.scss';

const { Text } = Typography;
export const loginPosition = ['/', '/dich-vu-forex-rebates-hoan-phi-forex-la-gi', '/login'];

const LoginForm = ({ toForgotPassword, translate }) => {
  const [error, setError] = useState();
  const dispatchApp = useDispatch().app;
  const [form] = Form.useForm();
  const positionLogin = useSelector((state) => state.app.auth.positionLogin);
  const afterLoginSuccess = useSelector((state) => state.app.auth.afterLoginSuccess);
  // const { setFieldsValue } = form;

  // useEffect(() => {
  //   if (agencyCode && agencyCode !== '') {
  //     setFieldsValue({agencyCode: agencyCode});
  //   }
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then(async (values) => {
        try {
          const res = await login(values);
          if (res.data) {
            handleAfterLoginComplete(dispatchApp, res.data, positionLogin, {
              afterLoginSuccess,
            });

            if (positionLogin === 'BANNER_SLIDER') {
              Router.push('/dashboard?banner=rebate');
            } else {
              Router.push(loginPosition.includes(Router.asPath) ? '/dashboard' : Router.asPath);
            }
          }
        } catch (e) {
          dispatchApp.setLoginUser();
          if (e.response && e.response.data && e.response.data.code === 1000) {
            setError('Tài khoản chưa được xác thực!');
          } else {
            setError('Tên người dùng hoặc mật khẩu không đúng!');
          }
        }
      })
      .catch(() => {});
  };

  const InputAddonBefore = ({ text }) => {
    return <div className={styles.inputAddonBefore}>{text}</div>;
  };

  return (
    <Form name="login-form" form={form} className={styles.vnrLoginForm} hideRequiredMark>
      {error && <Text type="danger">{error}</Text>}
      <Row style={{ position: 'relative' }}>
        <InputAddonBefore text={<div><span className={styles.requiredMark}>*</span>{translate.popupLogin.loginEmail}</div>} />
        <Form.Item
          name="username"
          rules={[
            {
              type: 'email',
              message: 'Email không hợp lệ',
            },
            {
              required: true,
              message: 'Nhập Email',
            },
          ]}
        >
          <Input placeholder={translate.popupLogin.loginEmail} className={styles.inputForm} onPressEnter={handleSubmit} />
        </Form.Item>
      </Row>

      <Row style={{ position: 'relative' }}>
        <InputAddonBefore text={<div><span className={styles.requiredMark}>*</span>{translate.popupLogin.loginPassword}</div>} />
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Nhập mật khẩu',
            },
          ]}
        >
          <Input.Password
            placeholder={translate.popupLogin.loginPassword}
            className={styles.inputForm}
            onPressEnter={handleSubmit}
          />
        </Form.Item>
      </Row>

      {/* <Row style={{ position: 'relative' }}>
        <InputAddonBefore text="Mã của agency" />
        <Form.Item
          name="agencyCode"
        >
          <Input
            placeholder="Mã của agency"
            className={styles.inputForm}
            disabled
          />
        </Form.Item>
      </Row> */}
      <div className={styles.checkbox}>
        <Checkbox defaultChecked>
          {translate.popupLogin.checkboxText}
        </Checkbox>
      </div>
      <div className={styles.formAction}>
        <Form.Item>
          <Button className={styles.buttonLogin} type="primary" onClick={handleSubmit}>
            <div className={styles.loginImage}>
              <LoginWhite />
            </div>
            <span className={styles.textLogin}>{translate.popupLogin.login}</span>
          </Button>
        </Form.Item>
        <div className={styles.buttonForgotPassword} onClick={toForgotPassword}>
          {translate.popupLogin.forgotPassword}
        </div>
      </div>
    </Form>
  );
};

export default LoginForm;
