// import { localStorageUtil } from '@utils/storageUtil';
import { useRouter } from 'next/router';
import cn from 'public/locales/cn.js';
import en from 'public/locales/en.js';
import vi from 'public/locales/vi.js';

const useTranslate = () => {
  const { locale } = useRouter();
  const localeList = {
    vi: vi,
    en: en,
    cn: cn
  }
  // const localeStorage = localStorageUtil.getItem('locale_storage');
  const langRoute = localeList[locale];
  // const langStorage = localeStorage === 'vi' ? vi : en;

  // if (localeStorage) return langStorage;

  return langRoute;
};

export default useTranslate;