import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Router, { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInAppBrowser } from '../../../hook/Browser';
import { socialLogin } from '../../../services/auth.service';
import { setInfoUserLogin } from '../../../utils/auth-helper';
import { getMetaDataTrackingWhenRegister } from '../../../utils/auth-helper';
import { handleAfterLoginComplete } from '../../Auth';
import { loginPosition } from '../LoginForm/login';
import FacebookLoginButton from './facebook';
import GoogleLoginButton from './google';
import styles from './SocialLogin.module.scss';

const redirectAfterLogin = ({ url, user }) => {
  if (!user) return;
  if (typeof window === 'undefined') return;
  
  if (Router.asPath.includes('/trading-academy')) {
    return;
  }

  Router.push(loginPosition.includes(Router.asPath) ? '/dashboard' : Router.asPath);
};

export const getTrackingDataAndLogin = async ({
  data,
  router,
  redirectUrl,
  dispatchApp,
  positionLogin,
  afterLoginSuccess,
  agencyCode
}) => {
  const metaTracking = getMetaDataTrackingWhenRegister();
  let registrationRole = '';
  if (router.pathname.startsWith('/login/ctv')) {
    registrationRole = 'COLLABORATOR';
  }
  if (router.pathname.startsWith('/login/broker')) {
    registrationRole = 'COLLABORATOR_BROKER';
  }
  const res = await socialLogin({ ...data, ...metaTracking, registrationRole, agencyCode });
  if (res.data) {
    const user = { ...res.data.user };
    user.roles = res.data.user.roles.map((role) => role.code);
    setInfoUserLogin({
      data: {
        ...res.data,
        roles: user.roles,
      },
    });
    dispatchApp.setLoginUser(user);
    dispatchApp.hiddenPopupLogin();
    handleAfterLoginComplete(dispatchApp, user, positionLogin, {
      afterLoginSuccess,
    });

    if (positionLogin === 'BANNER_SLIDER') {
      Router.push('/dashboard?banner=rebate');
      return;
    }
    redirectAfterLogin({ redirectUrl, user });

    return;
  }
};

const SocialLogin = ({ redirectUrl = '/dashboard', agencyCode }) => {
  const dispatchApp = useDispatch().app;
  const [loading, setLoading] = useState(false);
  const positionLogin = useSelector((state) => state.app.auth.positionLogin);
  const afterLoginSuccess = useSelector((state) => state.app.auth.afterLoginSuccess);
  const router = useRouter();
  const [isInAppBrowser, isFacebookInAppBrowser] = useInAppBrowser();

  const handleLoginSuccess = async (data) => {
    setLoading(true);
    try {
      await getTrackingDataAndLogin({
        data,
        router,
        dispatchApp,
        redirectUrl,
        positionLogin,
        afterLoginSuccess,
        agencyCode
      });
    } catch (e) {
      dispatchApp.setLoginUser({});
      console.error(e);
      console.error('Đăng nhập không thành công');
    } finally {
      setLoading(false);
    }
  };

  return isInAppBrowser && !isFacebookInAppBrowser ? null : (
    <div id="socialLoginList">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}>
        <div className={styles.social}>
          <div className={styles.buttonSocial}>
            {(!isInAppBrowser || isFacebookInAppBrowser) && (
              <FacebookLoginButton
                onLoginSuccess={handleLoginSuccess}
                onLoginFailure={console.error}
              />
            )}
          </div>
          {!isInAppBrowser && (
            <div className={styles.buttonSocial}>
              <GoogleLoginButton
                onLoginSuccess={handleLoginSuccess}
                onLoginFailure={console.error}
              />
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default SocialLogin;
