import Cookies from "js-cookie";

export const setCookie = (name, value, days) => {
  if (!days) {
    return;
  }
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const getCookie = name => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = name => {
  document.cookie = name + '=; Max-Age=-99999999;';
};

export const setCookiesAcrossSubdomains = (name, value) => {
  if (process.env.NODE_ENV === 'development') {
    Cookies.set(name, value, { domain: 'localhost', expires: 365  });
    return;
  };
  const cookieDomain = '.' + window.location.hostname.split('.').slice(-2).join('.');
  
  Cookies.set(name, value, { domain: cookieDomain, expires: 365  })
}

/*

setCookie('ppkcookie','testcookie',7);

var x = getCookie('ppkcookie');
if (x) {
    [do something with x]
}

*/
