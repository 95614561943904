import VnrLink from '@components/VnrLink';
import React from "react";

export const BROKER_STATUS = {
  WAITING_OPEN_ACCOUNT: {
    code: 'WAITING_OPEN_ACCOUNT',
    message: {
      vi: 'Tiếp tục liên kết',
      en: 'Continue to link',
      cn: '继续链接'
    },
    order: 1,
  },
  WAITING_FOR_VERIFICATION: {
    code: 'WAITING_FOR_VERIFICATION',
    message: {
      vi: 'Chờ xác thực',
      en: 'Wait for confirmation',
      cn: '等待确认'
    },
    order: 2,
  },
  VERIFIED: {
    code: 'VERIFIED',
    message: {
      vi: 'Đã xác thực',
      en: 'Verified',
      cn: '已验证'
    },
    order: 3,
  },
  VERIFICATION_FAILED: {
    code: 'VERIFICATION_FAILED',
    message: {
      vi: 'Xác thực không thành công',
      en: 'Verify failed',
      cn: '验证失败'
    },
    order: 4,
  },
};

export const PAYMENT_METHOD_STATUS = {
  WAITING_FOR_VERIFICATION: {
    code: 'WAITING_FOR_VERIFICATION',
    message: 'Chờ xác thực',
  },
  VERIFIED: {
    code: 'VERIFIED',
    message: 'Đã xác thực',
  },
  VERIFICATION_FAILED: {
    code: 'VERIFICATION_FAILED',
    message: 'Xác thực không thành công',
  },
};

export const ACTIVITY_STATUS = {
  VNREBATE_BROKER: {
    code: 'VNREBATE_BROKER',
    message: 'Nhận tiền rebate',
  },
  TRANSFER_SENT: {
    code: 'TRANSFER_SENT',
    message: 'Chuyển tiền',
  },
};

export const REBATE_TYPE = {
  PERCENT: {
    code: 'PERCENT',
  },
  MONEY: {
    code: 'MONEY',
  },
};

export const REBATE_MONTHLY_STATUS = {
  VALID: {
    code: 'VALID',
  },
  INVALID: {
    code: 'INVALID',
  },
  WAITING_FOR_APPROVAL: {
    code: 'WAITING_FOR_APPROVAL',
  },
  APPROVED: {
    code: 'APPROVED',
  },
  UNAPPROVED: {
    code: 'UNAPPROVED',
  },
};

export const REBATE_AWARD_TARGET_CONFIG = {
  TARGET_TRO_THANH_THANH_VIEN: {
    code: 'TARGET_TRO_THANH_THANH_VIEN',
    name: (
      <div>
        Truy cập Miễn phí <br></br>
        <VnrLink to="/trading-academy/hoc-dau-tu-forex-co-ban">
          Khóa 1: "Khoá Đầu tư Forex cơ bản"
        </VnrLink>
      </div>
    ),
    title: <div>Trở thành thành viên VnRebates</div>,
    img: '/images/connect_3.png',
    showProcessBar: false,
    value: true,
  },
  TARGET_MO_TAI_KHOAN: {
    code: 'TARGET_MO_TAI_KHOAN',
    name: (
      <div>
        Truy cập Miễn phí <br></br>
        <VnrLink to="/trading-academy/hoc-price-action-a-z">
          Khóa 2: "Khoá Price Action A-Z"
        </VnrLink>
      </div>
    ),
    title: <div>Mở tài khoản tại Sàn giao dịch liên kết VnRebates</div>,
    img: '/images/connect_2.png',
    showProcessBar: false,
    value: true,
  },
  TARGET_NAP_TIEN_100: {
    code: 'TARGET_NAP_TIEN_100',
    name: (
      <div>
        Truy cập Miễn phí <br></br>
        <VnrLink to="/trading-academy/hoc-dau-tu-forex-nang-cao">
          Khóa 3: "Khoá Đầu tư Forex nâng cao"
        </VnrLink>
      </div>
    ),
    title: <div>Nạp tiền tối thiểu 2&#36; vào tài khoản của bạn</div>,
    guideline: 'Gửi hình ảnh qua fanpage để xác nhận Đã nạp tiền',
    img: '/images/connect_1.png',
    showProcessBar: false,
    value: 100,
    initialValue: 0,
    completedValue: 100,
  },
  TARGET_NAP_TIEN_500: {
    code: 'TARGET_NAP_TIEN_500',
    name: (
      <div>
        Truy cập Miễn phí <br></br>
        <VnrLink to="/trading-academy/khoa-hoc-price-action-chuyen-sau">
          Khóa 4: "Khóa học Price Action Chuyên Sâu <br></br>+ Phân tích Khối lượng"
        </VnrLink>
      </div>
    ),
    title: <div>Nạp tiền 200&#36; vào tài khoản của bạn</div>,
    guideline: 'Gửi hình ảnh qua fanpage để xác nhận Đã nạp tiền',
    img: '/images/connect.png',
    showProcessBar: false,
    value: 500,
    initialValue: 100,
    completedValue: 500,
  },
  TARGET_DAT_HOAN_PHI_1000: {
    code: 'TARGET_DAT_HOAN_PHI_1000',
    name: <div>AirTag 45&#36;</div>,
    title: <div>Đạt 1,000&#36; hoàn phí</div>,
    img: '/images/airtag.png',
    showProcessBar: true,
    value: 1000,
    initialValue: 0,
    completedValue: 1000,
  },
  TARGET_DAT_HOAN_PHI_4000: {
    code: 'TARGET_DAT_HOAN_PHI_4000',
    name: <div>Airpod 300&#36;</div>,
    title: <div>Đạt 4,000&#36; hoàn phí</div>,
    img: '/images/airpod.png',
    showProcessBar: true,
    value: 4000,
    initialValue: 1000,
    completedValue: 4000,
  },
  TARGET_DAT_HOAN_PHI_14000: {
    code: 'TARGET_DAT_HOAN_PHI_14000',
    name: <div>Smart Watch 500&#36;</div>,
    title: <div>Đạt 14,000&#36; hoàn phí</div>,
    img: '/images/smart-watch.png',
    showProcessBar: true,
    value: 14000,
    initialValue: 4000,
    completedValue: 14000,
  },
  TARGET_DAT_HOAN_PHI_40000: {
    code: 'TARGET_DAT_HOAN_PHI_40000',
    name: <div>Iphone hoặc Macbook 1300&#36;</div>,
    title: <div>Đạt 40,000&#36; hoàn phí</div>,
    img: '/images/macbook.png',
    showProcessBar: true,
    value: 40000,
    initialValue: 14000,
    completedValue: 40000,
  },
  TARGET_DAT_HOAN_PHI_100000: {
    code: 'TARGET_DAT_HOAN_PHI_100000',
    name: <div>Sh mode 3000&#36;</div>,
    title: <div>Đạt 100,000&#36; hoàn phí</div>,
    img: '/images/motor-cycle.png',
    showProcessBar: true,
    value: 100000,
    initialValue: 40000,
    completedValue: 100000,
  },
};

export const POPUP_TARGET_REWARD_TYPE = {
  'hoc-price-action-a-z': {
    header: 'Hoàn thành yêu cầu',
    headerRequirementText:
      'Để truy cập “Khóa học Price Action A-Z” này, bạn cần: ',
    requirements: [`Gửi xác thực “Liên kết tài khoản” bên dưới thành công`],
    footerLogo: '/images/course_forex_basic.png',
  },
  'hoc-dau-tu-forex-nang-cao': {
    header: 'Hoàn thành yêu cầu',
    headerRequirementText:
      'Để truy cập “Khóa học đầu tư Forex nâng cao” này, bạn cần:',
    requirements: [
      `Gửi xác thực “Liên kết tài khoản” bên dưới thành công`,
      `Nạp tiền vào tài khoản đó tối thiểu 2$`,
    ],
    footerLogo: '/images/course_forex_advanced.png',
  },
  'khoa-hoc-price-action-chuyen-sau': {
    header: 'Hoàn thành yêu cầu',
    headerRequirementText:
      'Để truy cập “Khóa học Price Action Chuyên sâu” này, bạn cần:',
    requirements: [
      `Gửi xác thực “Liên kết tài khoản” bên dưới thành công`,
      `Nạp tiền vào tài khoản đó tối thiểu 200$`,
    ],
    footerLogo: '/images/course_forex_expert.png',
  },
};

export const POPUP_CONFIRM_BY_MESSENGER_TYPE = {
  'hoc-price-action-a-z': {
    headerRequirementText:
      'Mở tài khoản tại Sàn giao dịch liên kết VnRebates',
    reward: 'Truy cập Miễn Phí "Khoá Đầu tư Forex nâng cao"',
  },
  'hoc-dau-tu-forex-nang-cao': {
    headerRequirementText:
      'Nạp tiền tối thiểu 2$ vào tài khoản của bạn',
    reward: 'Truy cập Miễn Phí "Khoá Đầu tư Forex nâng cao"',
  },
  'khoa-hoc-price-action-chuyen-sau': {
    headerRequirementText:
      'Nạp tiền 200$ vào tài khoản của bạn',
    reward: 'Truy cập Miễn phí "Khóa học Price Action Chuyên Sâu + Phân tích Khối lượng"',
  },
};
