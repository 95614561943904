import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  status: undefined,
  username: undefined,
  verifyMessage: undefined,
  brokerId: undefined,
  userId: undefined,
  isReferAccount: undefined,
  customerName: undefined,
  isReferCommissionPay: undefined
};

export const entityBrokerAccount = {
  name: 'entityBrokerAccount',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
