import baseEntity from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  createdBy: undefined,
  createdDate: undefined,
  lastModifiedBy: undefined,
  lastModifiedDate: undefined,
  rebateTime: undefined,
  status: undefined,
};

export const entityRebateSiteMonthly = {
  name: 'entityRebateSiteMonthly',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
  },
  effects: () => ({}),
};
