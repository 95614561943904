import React, { useEffect,useState } from 'react';

import styles from './Profile.module.scss'

const Timer = ({deadline}) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    if (!deadline) return;

    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, [deadline]);

  if (!deadline) {
    return <div></div>
  }

  return (
    <div className={styles.timerWrapper}>
      <div>
        <div className={styles.timeValue}>{hours}</div>
        <div className={styles.timeUnit}>GIỜ</div>
      </div>
      <div className={styles.dot}>:</div>
      <div>
        <div className={styles.timeValue}>{minutes}</div>
        <div className={styles.timeUnit}>PHÚT</div>
      </div>
      <div className={styles.dot}>:</div>
      <div>
        <div className={styles.timeValue}>{seconds}</div>
        <div className={styles.timeUnit}>GIÂY</div>
      </div>
    </div>
  );
};

export default Timer;