import produce from 'immer';

export const pageManageUser = {
  name: 'pageManageUser',
  state: {
    searchParams: {
      waitingForIB: false,
      waitingForPay: false,
      waitingForPaymentMethod: false,
      q: '',
      broker: '',
      brokerAccountDateFrom: '',
      brokerAccountDateTo: '',
      registrationDate: '',
      registrationDateFrom: '',
      registrationDateTo: '',
      ibStatus: '',
      customerCare: false,
      ids: '',
      isDisableSort: false,
      setPhoneNumberFrom: '',
      setPhoneNumberTo: '',
    },
  },
  reducers: {
    saveSearchParams: produce((state, params) => {
      state.searchParams = {
        ...state.searchParams,
        ...params,
        broker: params.broker ? params.broker : ''
      };
    }),
  },
  effects: () => ({}),
};
